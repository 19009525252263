/* @ts-nocheck */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
    setAppViewerReducer, setViewerAppData,
    initializeAppViewerApp, setAppViewerAppObject,
    subscriptionGetUser, setSubscriptionAuthReducer,
    logAppVisit, getWebsiteResources, setViewerElementAppData,
    setBatchComputeCache, unsetBatchComputeCache
} from '../../../actions';
import ViewTypeParser from './viewTypeParser';
import functionParserWrapper from '../../../utils/functionParser/functionParserWrapper';
import {isEmpty, isEqual, uniq} from 'lodash';
import UserAgent from 'express-useragent';
import queryString from 'query-string';
import dotNotationMapper from '../../../utils/data/dotNotationMapper';
import JSONfn from 'json-fn';
import LZUTF8 from 'lzutf8';
import shortid from 'shortid';
import { withRouter } from 'react-router';
import moment from 'moment';
import encryptDecrypt from '../../../utils/cryptography/encryptDecrypt';
import cookies from 'browser-cookies';
import config from '../../../config/config';
import getParentElementHeirarchy from './utils/functions/element/getParentElementHeirarchy';
// import localforage from "localforage";
// import sizeof from 'object-sizeof';
// import indexedDbSetItem from '../../../utils/indexedDb/indexedDbSetItem';
import indexedDbClear from '../../../utils/indexedDb/indexedDbClear';
import indexedDbGetItem from '../../../utils/indexedDb/indexedDbGetItem';
import indexedDbSetItem from '../../../utils/indexedDb/indexedDbSetItem';
import { bulkAttributeParser } from '../../../utils/attribute/bulkAttributeParser';
import elementAttributeFunction from './utils/functions/attributes/elementAttributeFunction';
// import indexedDbGetItem from '../../../utils/indexedDb/indexedDbGetItem';
// import { get, set, delMany } from 'idb-keyval';
// import WebFont from 'webfontloader';

class AppViewer extends Component{
    constructor(props){
        super(props);
        let initResponse = this.decryptAndInitializeApp();
        if(initResponse['appData']){
            delete initResponse['appData'];
        }
        this.state = {
            appId : '',
            appLoadLifecycleTriggered : false,
            isWebComponent : false,
            extractionError : false,
            appStateInitialized : false,
            viewHeight : 0,
            viewWidth : 0,
            pageSize : 0,
            workerMap : {},
            // activeSession : false,
            stateChangeId : Math.random(),
            hasStateChangeFunction : true,
            dataChangeLifecycleFunctions : [],
            pageDataChangeLifecycleFunctionMap : {},
            dataChangeFunctionsExtracted : false,
            ...initResponse
        }
        // this.initializePrimaryWebWorker();
        // this.initializeWebsiteResourceWebWorker();
    }
    workerInstance;
    websiteResourceWorkerInstance;
    webComponentWorkerInstance;
    calculateListKeyTrackerWorkerInstance;
    appDataChangeWorkerInstance;
    languageCheckInterval;
    bulkAttributeWorkerInstance;

    componentDidMount(){
        // const {appMap} = this.props;
        // const {appId} = this.state;
        // this.loadWebFonts();
        // this.websiteResourceWorkerInstance = websiteResourceWorker();
        // this.workerInstance = worker();
        // this.initializeListUpdateTrackerWebWorker();
        if(!this.state.isWebComponent){
            let indexedDbSessionKey = sessionStorage.getItem('indexedDbSessionKey');
            if(!indexedDbSessionKey){
                indexedDbSessionKey = `store_${Math.random().toFixed(7).slice(3)}`;
                sessionStorage.setItem('indexedDbSessionKey', indexedDbSessionKey);
            }
            sessionStorage.setItem('inhouzParentAppId', this.state.appId);
            // let sessionKeys = localStorage.getItem(`${this.state.appId}_keys`);
            // if(sessionKeys){
            //     try{    
            //         let parsedKeys = JSON.parse(sessionKeys);
            //         if(Array.isArray(parsedKeys)){
            //             parsedKeys.push(indexedDbSessionKey);
            //         }else{
            //             parsedKeys = [indexedDbSessionKey];
            //         }
            //         localStorage.setItem(`${this.state.appId}_keys`, JSON.stringify(uniq(parsedKeys)));
            //     }catch(e){
            //         console.log('localstorage set sessionkeys catch error', e);
            //         return;
            //     }
            // }else{
            //     localStorage.setItem(`${this.state.appId}_keys`, JSON.stringify([indexedDbSessionKey]));
            // }
        }
        // this.initializePrimaryWebWorker();
        // this.initializeWebsiteResourceWebWorker();
        this.initializeAppDataChangeWebWorker();
        // this.initializeBulkAttributeWorker();
        this.extractDataChangeFunctionsByPage();
        // this.setIndexedDbAppData();
        this.getWebResources();
        this.checkActiveSubscription();
        this.triggerLifecycleFunctions('onAppLoad');
        // this.parsePageLifeCycles('onPageLoad', appMap[appId] && appMap[appId]['pageInFocusId']);
        this.initializeAppState();
        if(this.props.isWebComponent){
            this.setAppDataFunction(this.props.dataParameter);
        }
        // this.setIndexedDbWebsiteResources();
        this.setViewDimensions();
        window.addEventListener('resize', this.handleResize);
        this.unlisten = this.props.history.listen((location, action) => {
            if(!this.props.isWebComponent){
                this.extractRouteParameters();
                this.parsePages();
                this.sendTrafficMetrics('pageVisit');
            }
        });
        this.initializePageDimensions();
        this.setUserData();
        this.triggerParentElementHeirarchy();
        this.sendTrafficMetrics('appVisit');
        this.sendTrafficMetrics('pageVisit');
        window.addEventListener('beforeunload', this.handleBrowserUnload);
    }

    componentDidUpdate(prevProps){
        const {
            appMap, subscriberData, dataParameter,
            isWebComponent, routeProps, userProp,
            parentDeviceMode, workerInitTracker
        } = this.props;
        const {appId, appLoadLifecycleTriggered} = this.state;

        if(
            isEmpty(
                prevProps['appMap'] && prevProps['appMap'][appId] && 
                prevProps['appMap'][appId]['appInFocus']
            ) &&
            !isEqual(
                (appMap[appId] && appMap[appId]['appInFocus']),
                (
                    prevProps['appMap'] && prevProps['appMap'][appId] && 
                    prevProps['appMap'][appId]['appInFocus']
                )
            )
        ){
            this.extractDataChangeFunctionsByPage();
        }

        if(
            !isEqual(
                (appMap[appId] && appMap[appId]['pageInFocusId']),
                (
                    prevProps['appMap'] && prevProps['appMap'][appId] && 
                    prevProps['appMap'][appId]['pageInFocusId']
                )
            )
        ){
            // this.parsePageLifeCycles('onPageLoad', appMap[appId] && appMap[appId]['pageInFocusId']);
            this.parsePageLifeCycles(
                'onPageDestroy', 
                prevProps['appMap'] && prevProps['appMap'][appId] && 
                prevProps['appMap'][appId]['pageInFocusId']
            );
        }

        if(
            !isEqual(
                (appMap[appId] && appMap[appId]['mappedQueryStrings']),
                (
                    prevProps['appMap'] && prevProps['appMap'][appId] && 
                    prevProps['appMap'][appId]['mappedQueryStrings']
                )
            ) ||
            !isEqual(
                (appMap[appId] && appMap[appId]['mappedRouteParams']),
                (
                    prevProps['appMap'] && prevProps['appMap'][appId] && 
                    prevProps['appMap'][appId]['mappedRouteParams']
                )
            )
        ){
            this.triggerLifecycleFunctions('onRouterChange');
        }

        if(
            !isEqual(
                (appMap[appId] && appMap[appId]['App_Data']),
                (
                    prevProps['appMap'] && prevProps['appMap'][appId] && 
                    prevProps['appMap'][appId]['App_Data']
                )
            )
        ){
            // await this.setIndexedDbAppData(appMap[appId] && appMap[appId]['App_Data']);
            this.updateTrackerField('appDataChangeTracker');
            this.triggerStateUpdateLifecycleFunctions(
                appMap[appId] && appMap[appId]['App_Data'],
                prevProps['appMap'] && prevProps['appMap'][appId] && 
                prevProps['appMap'][appId]['App_Data']
            );
            if(!isWebComponent){
                this.languageCheck(appMap[appId] && appMap[appId]['App_Data']);
            }
        }

        // if(
        //     !isEqual(
        //         (appMap[appId] && appMap[appId]['appElementsMap']),
        //         (
        //             prevProps['appMap'] && prevProps['appMap'][appId] && 
        //             prevProps['appMap'][appId]['appElementsMap']
        //         )
        //     ) ||
        //     !isEqual(
        //         (appMap[appId] && appMap[appId]['appFunctionMap']),
        //         (
        //             prevProps['appMap'] && prevProps['appMap'][appId] && 
        //             prevProps['appMap'][appId]['appFunctionMap']
        //         )
        //     )
        // ){
        //     this.setIndexedDbWebsiteResources();
        // }

        if(
            !isEqual(subscriberData, prevProps.subscriberData)
        ){
            this.setUserData();
            this.checkActiveSubscription();
        }

        if(!isEqual(dataParameter, prevProps.dataParameter)){
            this.setAppDataFunction(dataParameter);
        }

        if(
            isWebComponent && 
            !isEqual(routeProps, prevProps.routeProps)
        ){
            this.updateRouteProps();
        }

        if(
            isWebComponent && 
            !isEqual(userProp, prevProps.userProp)
        ){
            this.updateUserProps();
        }

        if(
            !appLoadLifecycleTriggered && 
            !isEqual(
                (appMap[appId] && appMap[appId]['appId']),
                (
                    prevProps['appMap'] && prevProps['appMap'][appId] && 
                    prevProps['appMap'][appId]['appId']
                )
            )
        ){
            this.triggerLifecycleFunctions('onAppLoad');
        }

        if(
            isWebComponent && 
            !isEqual(parentDeviceMode, prevProps.parentDeviceMode)
        ){
            this.handleResize(null, true)
        }

        if(
            !isEqual(
                (appMap[appId] && appMap[appId]['websiteResourceUpdateTracker']),
                (
                    prevProps['appMap'] && prevProps['appMap'][appId] && 
                    prevProps['appMap'][appId]['websiteResourceUpdateTracker']
                )
            )
        ){
            this.triggerParentElementHeirarchy();
        }

        if(
            !this.websiteResourceWorkerInstance && 
            !isEqual(workerInitTracker, prevProps.workerInitTracker)
        ){
            this.initializeWebsiteResourceWebWorker();
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        const {
            appMap, subscriberData, dataParameter,
            userProp, routeProps,
            isWebComponent, parentDeviceMode,
            workerInitTracker
        } = this.props;

        let appId = this.state.appId || nextState.appId;
        if(
            (this.state['stateChangeId'] !== nextState['stateChangeId']) ||
            !isEqual(
                (
                    appMap[appId] && appMap[appId]['App_Data']),
                (
                    nextProps['appMap'] && nextProps['appMap'][appId] && 
                    nextProps['appMap'][appId]['App_Data']
                )
            ) ||
            !isEqual(
                (
                    appMap[appId] && appMap[appId]['pageInFocusId']),
                (
                    nextProps['appMap'] && nextProps['appMap'][appId] && 
                    nextProps['appMap'][appId]['pageInFocusId']
                )
            ) ||
            !isEqual(
                (
                    appMap[appId] && appMap[appId]['mappedRouteParams']),
                (
                    nextProps['appMap'] && nextProps['appMap'][appId] && 
                    nextProps['appMap'][appId]['mappedRouteParams']
                )
            ) ||
            !isEqual(
                (
                    appMap[appId] && appMap[appId]['mappedQueryStrings']),
                (
                    nextProps['appMap'] && nextProps['appMap'][appId] && 
                    nextProps['appMap'][appId]['mappedQueryStrings']
                )
            ) ||
            !isEqual(
                (
                    appMap[appId] && appMap[appId]['websiteResourceUpdateTracker']),
                (
                    nextProps['appMap'] && nextProps['appMap'][appId] && 
                    nextProps['appMap'][appId]['websiteResourceUpdateTracker']
                )
            ) ||
            !isEqual(subscriberData, nextProps.subscriberData) ||
            !isEqual(
                dataParameter,
                nextProps.dataParameter
            ) ||
            !isEqual(
                userProp,
                nextProps.userProp
            ) ||
            !isEqual(
                routeProps,
                nextProps.routeProps
            ) ||
            (
                isWebComponent && 
                !isEqual(
                    parentDeviceMode,
                    nextProps.parentDeviceMode
                )
            ) 
            || !isEqual(workerInitTracker, nextProps.workerInitTracker) 
            // ||
            // !isEqual(
            //     (
            //         appMap[appId] && appMap[appId]['appElementsMap']),
            //     (
            //         nextProps['appMap'] && nextProps['appMap'][appId] && 
            //         nextProps['appMap'][appId]['appElementsMap']
            //     )
            // ) || 
            // !isEqual(
            //     (
            //         appMap[appId] && appMap[appId]['appFunctionMap']),
            //     (
            //         nextProps['appMap'] && nextProps['appMap'][appId] && 
            //         nextProps['appMap'][appId]['appFunctionMap']
            //     )
            // )
        ){
            return true;
        }else{
            return false;
        }
    }

    componentWillUnmount(){
        this.clearIndexBb();
        window.removeEventListener('resize', this.handleResize);
        window.removeEventListener('beforeunload', this.handleBrowserUnload);
        if(this.unlisten){
            this.unlisten();
        }
        this.clearState();
        // this.removeWebworkers();
        this.workerInstance = null;
        this.websiteResourceWorkerInstance = null;
        this.appDataChangeWorkerInstance = null;
        if(this.languageCheckInterval){
            clearTimeout(this.languageCheckInterval);
        }
    }

    languageCheck = (data={}) => {
        if(this.languageCheckInterval){
            clearTimeout(this.languageCheckInterval);
        }
        this.languageCheckInterval = setTimeout(() => {
            if(
                data['appSettings'] && 
                data['appSettings']['lang'] && 
                (data['appSettings']['lang'] !== this.state.lang)
            ){
                this.setState({
                    lang : data['appSettings']['lang'],
                    stateChangeId : Math.random()
                })
            }
        }, 500);
    }

    // setIndexedDbAppData = async (App_Data) => {
    //     let inhouzParentAppId = sessionStorage.getItem('inhouzParentAppId');
    //     let sessionKey = sessionStorage.getItem('indexedDbSessionKey');
    //     let data;
    //     if(!App_Data){
    //         const {
    //             appMap={}
    //         } = this.props;
    //         let appObj = appMap[this.state.appId];
    //         if(appObj){
    //             data = appObj['App_Data']
    //         }
    //     }
    //     let key;
    //     if(!this.state.appDataWorkerKey){
    //         key = `${inhouzParentAppId}${this.props.indexDbProp || ''}_${sessionKey}_App_Data`;
    //         this.setState({
    //             appDataWorkerKey : key,
    //             stateChangeId : Math.random()
    //         });
    //     }else{
    //         key = this.state.appDataWorkerKey;
    //     }
        
    //     await indexedDbSetItem({
    //         indexDbName : inhouzParentAppId,
    //         storeName : sessionKey,
    //         key, 
    //         data : App_Data || data || {}
    //     });
    // }

    // setIndexedDbWebsiteResources = async () => {
    //     const {
    //         appMap={}
    //     } = this.props;
    //     let appObj = appMap[this.state.appId];
    //     if(appObj){
    //         let {
    //             appFunctionMap={},
    //             appElementsMap={}
    //         } = appObj;
    //         let inhouzParentAppId = sessionStorage.getItem('inhouzParentAppId');
    //         let sessionKey = sessionStorage.getItem('indexedDbSessionKey');
    //         indexedDbSetItem({
    //             indexDbName : inhouzParentAppId,
    //             storeName : sessionKey,
    //             key : `${inhouzParentAppId}${this.props.indexDbProp || ''}_${sessionKey}_functionMap`, 
    //             data : appFunctionMap
    //         });

    //         let isOldIE = false, batchData=[];
    //         if(
    //             navigator && 
    //             (
    //                 navigator.userAgent.indexOf('MSIE')!==-1
    //                 || navigator.appVersion.indexOf('Trident/') > -1
    //             )
    //         ){
    //             isOldIE = true;
    //         }
            
    //         if(isOldIE){
    //             for (let elementId in appElementsMap){
    //                 // let element = appElementsMap[elementId];
    //                 indexedDbSetItem({
    //                     indexDbName : inhouzParentAppId,
    //                     storeName : sessionKey,
    //                     key : `${inhouzParentAppId}_${sessionKey}_element_${elementId}`, 
    //                     data : appElementsMap[elementId]
    //                 });
    //             }
    //         }else{
    //             for (let elementId in appElementsMap){
    //                 // let element = appElementsMap[elementId];
    //                 batchData.push([
    //                     `${inhouzParentAppId}_${sessionKey}_element_${elementId}`,
    //                     appElementsMap[elementId]
    //                 ]);
    //             }
    //             indexedDbSetItem({
    //                 indexDbName : inhouzParentAppId,
    //                 storeName : sessionKey,
    //                 // key : `${inhouzParentAppId}_${sessionKey}_element_${elementId}`, 
    //                 data : batchData,
    //                 bulkSet : true
    //             });
    //             batchData = null;
    //         }
    //     }
    // }

    clearIndexBb = async (bulkClear=false) => {
        console.log('clearIndexDb triggered')
        let inhouzParentAppId = sessionStorage.getItem('inhouzParentAppId');
        let sessionKey = sessionStorage.getItem('indexedDbSessionKey');
        let sessionKeys = localStorage.getItem(`${inhouzParentAppId}_keys`);
        // const {
        //     appMap={}
        // } = this.props;
        // let appObj = appMap[this.state.appId];
        let keys = [
            // `${inhouzParentAppId}${this.props.indexDbProp || ''}_${sessionKey}_App_Data`
            //,
            // `${inhouzParentAppId}${this.props.indexDbProp || ''}_${sessionKey}_functionMap`
        ];
        // if(appObj){
        //     let {
        //         appElementsMap={}
        //     } = appObj;
        //     for (let elementId in appElementsMap){
        //         keys.push(`${inhouzParentAppId}_${sessionKey}_element_${elementId}`);
        //     }
        // }

        await indexedDbClear({
            indexDbName : inhouzParentAppId,
            storeName : sessionKey,
            keysToDelete : keys,
            sessionKeys : sessionKeys ? JSON.parse(sessionKeys) : [],
            appId : inhouzParentAppId,
            bulkClear : this.props.isWebComponent ? false : bulkClear
        });
    }

    initializePrimaryWebWorker = async () => {
        if(!this.workerInstance){
            if(!this.props.workerInstanceProp){
                /* eslint-disable-next-line */
                let worker = await import('workerize-loader?inline!../../../worker/attributeFunction.worker.js');
                this.workerInstance = worker.default();
                worker = null;
            }else{
                this.workerInstance = this.props.workerInstanceProp();
            }
        }
    }

    initializeBulkAttributeWorker = () => {
        if(
            !this.bulkAttributeWorkerInstance && 
            this.props.bulkAttributeWorkerInstanceProp
        ){
            this.bulkAttributeWorkerInstance = this.props.bulkAttributeWorkerInstanceProp();
        }
    }

    initializeAppDataChangeWebWorker = () => {
        if(
            !this.appDataChangeWorkerInstance && 
            this.props.appDataChangeWorkerInstanceProp
        ){
            this.appDataChangeWorkerInstance = this.props.appDataChangeWorkerInstanceProp();
        }   
    }

    initializeWebsiteResourceWebWorker = async () => {
        if(!this.websiteResourceWorkerInstance){
            if(!this.props.websiteResourceWorkerInstanceProp){
                /* eslint-disable-next-line */
                let worker = await import('workerize-loader?inline!../../../worker/getWebsiteResources.worker.js');
                this.websiteResourceWorkerInstance = worker.default();
                this.getWebResources();
                worker = null;
            }else{
                this.websiteResourceWorkerInstance = this.props.websiteResourceWorkerInstanceProp();
                this.getWebResources();
            }
        }
    }

    // initializeWebComponentWebWorker = async () => {
    //     if(!this.props.webComponentWorkerInstanceProp){
    //         /* eslint-disable-next-line */
    //         let worker = await import('workerize-loader?inline!../../../worker/getWebComponent.worker.js');
    //         this.webComponentWorkerInstance = worker.default();
    //         worker = null;
    //     }else{
    //         this.webComponentWorkerInstance = this.props.webComponentWorkerInstanceProp();
    //     }
    // }

    // initializeListUpdateTrackerWebWorker = async () => {
    //     if(!this.props.calculateListKeyTrackerWorkerInstanceProp){
    //         /* eslint-disable-next-line */
    //         let worker = await import('workerize-loader?inline!../../../worker/calculateListKeyTracker.worker.js');
    //         this.calculateListKeyTrackerWorkerInstance = worker.default();
    //         worker = null;
    //     }else{
    //         this.calculateListKeyTrackerWorkerInstance = this.props.calculateListKeyTrackerWorkerInstanceProp();
    //     }
    // }

    // removeWebworkers = () => {
    //     if(
    //         !this.props.workerInstanceProp && 
    //         this.workerInstance && 
    //         this.workerInstance.terminate
    //     ){
    //         this.workerInstance.terminate();
    //     }
    //     if(
    //         !this.props.websiteResourceWorkerInstanceProp && 
    //         this.websiteResourceWorkerInstance && 
    //         this.websiteResourceWorkerInstance.terminate
    //     ){
    //         this.websiteResourceWorkerInstance.terminate();
    //     }
    //     if(
    //         !this.props.webComponentWorkerInstanceProp && 
    //         this.webComponentWorkerInstance && 
    //         this.webComponentWorkerInstance.terminate
    //     ){
    //         this.webComponentWorkerInstance.terminate();
    //     }
    //     if(
    //         !this.props.calculateListKeyTrackerWorkerInstanceProp && 
    //         this.calculateListKeyTrackerWorkerInstance && 
    //         this.calculateListKeyTrackerWorkerInstance.terminate
    //     ){
    //         this.calculateListKeyTrackerWorkerInstance.terminate();
    //     }
    // }

    // getPrimaryWorkerInstance = async () => {
    //     if(!this.workerInstance){
    //         await this.initializePrimaryWebWorker();
    //     }
    //     return this.workerInstance;
    // }

    // getWebsiteResourceWorkerInstance = () => {
    //     return this.websiteResourceWorkerInstance;
    // }

    // getWebComponentWorkerInstance = async () => {
    //     if(!this.webComponentWorkerInstance){
    //         await this.initializeWebComponentWebWorker();
    //     }

    //     return this.webComponentWorkerInstance;
    // }

    // getListUpdateTrackerWorkerInstance = async () => {
    //     if(!this.calculateListKeyTrackerWorkerInstance){
    //         await this.initializeListUpdateTrackerWebWorker();
    //     }

    //     return this.calculateListKeyTrackerWorkerInstance;
    // }

    handleBrowserUnload = () => {
        this.clearIndexBb(true);
        this.sendTrafficMetrics('sessionDuration');
    }

    getWebResources = async () => {
        let {
            getWebsiteResources, isWebComponent=false
        } = this.props;
        if(
            !isWebComponent 
            // && 
            // this.websiteResourceWorkerInstance
        ){
            let encryptedPayload = encryptDecrypt(JSON.stringify({
                [Math.random()] : Math.random(),
                expirationTimestamp : moment().add(30, 'seconds').unix() * 1000
            }), true);
    
            if(getWebsiteResources){
                let windowLocation = window.location;
                let {
                    origin=''
                } = windowLocation;
                let url = `${origin}/api/websiteresource/getwebsiteresources`;
                // let response = await this.websiteResourceWorkerInstance.getWebsiteResources(
                //     url,
                //     {
                //         encryptedPayload
                //     }
                // );

                let response = await fetch(
                    url, 
                    {
                        method : 'POST',
                        headers:{
                            'Accept':"application/json",
                            'Content-Type':"application/json",
                            'Cache': 'no-cache'
                        },
                        credentials: 'include',
                        mode: 'cors',
                        body:JSON.stringify({encryptedPayload})
                    }
                )
                .then(res => res.json())
                .catch((e) => {
                    console.log('/getWebsiteResources.worker.js fetch catch block error', e)
                    return {
                        error : {
                            message : e.message
                        }
                    };
                });
                getWebsiteResources(response);
                response = null;
                url = null;
                encryptedPayload = null;
                windowLocation = null;
                origin = null;
            }
        }

        getWebsiteResources = null;
        isWebComponent = null;
    }

    extractDataChangeFunctionsByPage = () => {
        const {
            appMap={}
        } = this.props;
        const {appId=''} = this.state;
        let appObj = appMap[appId] || {};
        const {
            appInFocus={}, parsedPageMap={}
        } = appObj;
        if(isEmpty(appInFocus)){
            return;
        }
        let {appLifeCycleFunctions = [], appType=''} = appInFocus;
        let functionList = appLifeCycleFunctions.filter(obj => {
            return obj['lifecycleMethod'] === 'onStateUpdate';
        });

        if(appType === 'webApp'){
            const {pageMap={}} = parsedPageMap;
            let pageDataChangeLifecycleFunctionMap = {};
            for (let k in pageMap){
                let pageObj = pageMap[k];
                const {
                    pageId=''
                } = pageObj;

                let pageFunctions = [];
                for (let i = 0; i < functionList.length; i++){
                    let functionObj = functionList[i];
                    const {pageIds=[]} = functionObj;
                    if(
                        (pageIds.length === 0) ||
                        pageIds.includes(pageId)
                    ){
                        pageFunctions.push(functionObj);
                    }
                }

                pageDataChangeLifecycleFunctionMap[pageId] = pageFunctions;
            }

            this.setState({
                pageDataChangeLifecycleFunctionMap,
                dataChangeFunctionsExtracted : true,
                stateChangeId : Math.random()
            });
        }else{
            this.setState({
                dataChangeLifecycleFunctions : functionList,
                dataChangeFunctionsExtracted : true,
                hasStateChangeFunction : functionList.length > 0 ? true : false,
                stateChangeId : Math.random()
            });
        }
    }

    triggerParentElementHeirarchy = () => {
        const {
            appMap={}, setAppViewerAppObject
        } = this.props;
        if(setAppViewerAppObject){
            const {appId=''} = this.state;
            let appObj = appMap[appId] || {};
            const {appElementsMap={}} = appObj;
            let processedElements = [];
            for (let k in appElementsMap){
                let element = appElementsMap[k];
                processedElements.push(element);
            }
            let pageRootElementMap = {}, modalElementIds=[],
            tabIdMap={}, sliderIdMap={}, tableIdMap={};

            for (let i = 0; i < processedElements.length; i++){
                let element = processedElements[i];
                let {elementId='', pageId='', rootElement=false, elementType=''} = element;
                if(rootElement){
                    pageRootElementMap[pageId] = elementId;
                }
                if(elementType === 'table'){
                    tableIdMap[elementId] = element;
                }else if(elementType === 'slider'){
                    sliderIdMap[elementId] = element;
                }else if(elementType === 'tabWrapper'){
                    tabIdMap[elementId] = element;
                }else if(elementType === 'modal'){
                    modalElementIds.push(elementId);
                }
            }

            let parentElementIdMap = getParentElementHeirarchy({
                pageRootElementMap,
                appElementsMap,
                tabIdMap,
                sliderIdMap,
                tableIdMap
            });

            setAppViewerAppObject({
                appId,
                data : {
                    parentElementIdMap
                }
            });
        }
    }

    sendTrafficMetrics = (trafficType) => {
        const {
            appMap={}
        } = this.props;
        let appObj = appMap[this.state.appId] || {};
        let {
            appInFocus={}, App_Data={}, environment='',
            pageInFocusId=''
        } = appObj;
        const {appSettings={}} = App_Data;
        let sessionRegistered, pageTimestamp;
        let payload = {
            environment,
            companyId : appInFocus['companyId'],
            appId : appInFocus['appId'],
            deviceType : appSettings['deviceMode']
        }
        let timestamp = new Date().getTime();
        if(trafficType === 'pageVisit'){
            let pathName = appSettings['route'] && appSettings['route']['pathname'];
            pageTimestamp = sessionStorage.getItem(`${pageInFocusId}_${pathName}_timestamp`);
            if(pageTimestamp && (timestamp < Number(pageTimestamp))){
                return;
            }
            payload = {
                ...payload,
                metricsType : 'pageVisit',
                pageInFocusId,
                pathName
            }
            sessionStorage.setItem(`${pageInFocusId}_${pathName}_timestamp`, moment().add(30, 'minutes').unix() * 1000);
        }else if(trafficType === 'appVisit'){
            sessionRegistered = sessionStorage.getItem('sessionStartTime');
            if(sessionRegistered){
                sessionStorage.setItem('sessionStartTime', new Date().getTime())
                return;
            }
            payload = {
                ...payload,
                metricsType : 'appVisit',
                referrer : window.document.referrer
            }
            sessionStorage.setItem('sessionStartTime', new Date().getTime())
        }else if(trafficType === 'sessionDuration'){
            let sessionStart = sessionStorage.getItem('sessionStartTime');
            if(sessionStart){
                let sessionDurationMillisecond = timestamp - Number(sessionStart);
                payload = {
                    ...payload,
                    metricsType : 'sessionDuration',
                    sessionDurationMillisecond
                }
                sessionStorage.setItem('sessionStartTime', new Date().getTime());
            }
        }

        let metricsUrl = `${config.devServerProxy}/api/appviewerlog/setappmetrics`;
        let encryptedPayload = encryptDecrypt(JSON.stringify({
            payload,
            expirationTimestamp : moment().add(30, 'seconds').unix() * 1000
        }), true);
        fetch(metricsUrl, {
            method : 'POST',
            headers:{
                'Accept':"application/json",
                'Content-Type':"application/json"
            },
            credentials: 'include',
            body: JSON.stringify({encryptedPayload})
        })
        .catch(e => {
            console.log('/appViewer sendAppVisitMetrics catch block', e);
            return;
        });
    }

    checkActiveSubscription = () => {
        let sessionTimestamp = cookies.get('sessionExpirationTimestamp');
        this.setState({
            activeSession : sessionTimestamp ? 
            Number(sessionTimestamp) > new Date().getTime()
            :
            false
        });
    }

    initializePageDimensions = () => {
        this.setState({
            viewHeight : window.innerHeight,
            viewWidth : window.innerWidth,
            stateChangeId : Math.random()
        })
    }

    // workerFunction = async (params={}) => {
    //     if(!this.workerInstance){
    //         this.initializePrimaryWebWorker();
    //     }
    //     if(this.workerInstance){
    //         let stringified = {
    //             key : params['key'],
    //             // appDataKey : this.state.appDataWorkerKey,
    //             usedFunctions : JSONfn.stringify({
    //                 historyPushFunction : this.historyPushFunction,
    //                 setStateFunction : params['setStateFunction'],
    //                 setElementAppData : this.setElementAppData,
    //                 setParentAppData : this.setParentAppData
    //             })
    //         }

    //         // console.log('stringified', stringified)
    //         // let size = sizeof(stringified)/1000;
    //         // console.log('size', size, this.state.pageSize)
    //         // this.setState({
    //         //     pageSize : this.state.pageSize + size
    //         // });
    //         let result = await this.workerInstance.expensive(stringified);
    //         delete stringified['usedFunctions'];
    //         stringified = null;
    //         params=null;
    //         return result;
    //     }
    // }

    // bulkAttributeWorkerFunction = async (params={}) => {
    //     const {setBatchComputeCache} = this.props
    //     if(!this.bulkAttributeWorkerInstance){
    //         this.initializeBulkAttributeWorker();
    //     }
    //     if(
    //         this.bulkAttributeWorkerInstance && 
    //         setBatchComputeCache
    //     ){
    //         let {
    //             appId='', elementId='', listItemPathId='', bulkParams={}
    //         } = params;
    //         // setBatchComputeCache({
    //         //     appId,
    //         //     elementId,
    //         //     listItemPathId,
    //         //     data : {}
    //         // });
    //         let dbKey = `${elementId}_${Math.random()}_${Math.random()}`;
    //         let stringified = {
    //             key : dbKey,
    //             appId,
    //             elementId,
    //             listItemPathId,
    //             usedFunctions : JSONfn.stringify({
    //                 historyPushFunction : this.historyPushFunction,
    //                 setStateFunction : params['setStateFunction'],
    //                 setElementAppData : this.setElementAppData,
    //                 setParentAppData : this.setParentAppData
    //             })
    //         }
    //         await indexedDbSetItem({
    //             key : dbKey,
    //             data : bulkParams
    //         });
    //         await this.bulkAttributeWorkerInstance.bulkAttributeParser(stringified);
    //         let accumulator =  await indexedDbGetItem({key : dbKey});
    //         indexedDbClear({keysToDelete : [dbKey]});
    //         // if(elementId === '660a4bb133f6ec00004a9975'){
    //         //     console.log('accumulator', accumulator)
    //         // }
    //         setBatchComputeCache({
    //             appId,
    //             elementId,
    //             listItemPathId,
    //             data : accumulator
    //         });
    //         // console.log('setBatchComputeCache', appId, elementId, listItemPathId, accumulator)
    //         // console.log('accumulator', accumulator)
    //         delete stringified['usedFunctions'];
    //         stringified = null;
    //         params=null;
    //         accumulator = null;
    //         dbKey = null;
    //         bulkParams = null;
    //         listItemPathId = null;
    //         elementId = null;
    //         appId = null;
    //         return;
    //     }
    // }

    workerFunction = (params={}) => {
        let result = elementAttributeFunction(params);
        params = null;
        return result;
    }

    bulkAttributeWorkerFunction = async (params={}) => {
        let {setBatchComputeCache} = this.props;
        if(
            setBatchComputeCache
        ){
            let {
                appId='', elementId='', listItemPathId='', bulkParams={}
            } = params;

            let stringified = {
                appId,
                elementId,
                listItemPathId,
                usedFunctions : {
                    historyPushFunction : this.historyPushFunction,
                    setStateFunction : params['setStateFunction'],
                    setElementAppData : this.setElementAppData,
                    setParentAppData : this.setParentAppData
                },
                bulkParams
            }
            let accumulator = bulkAttributeParser(stringified);
            setBatchComputeCache({
                appId,
                elementId,
                listItemPathId,
                data : accumulator
            });
            params=null;
            for (let k in bulkParams){
                bulkParams[k] = null;
            }
            bulkParams = null;
            listItemPathId = null;
            elementId = null;
            appId = null;
            for (let k in accumulator){
                accumulator[k] = null;
            }
            accumulator = null;
            for (let k in stringified){
                stringified[k] = null;
            }
            stringified = null;
            setBatchComputeCache = null;
            return;
        }
    }

    handleCachedAttributeCleanup = (key='') => {
        const {unsetBatchComputeCache} = this.props;
        if(unsetBatchComputeCache){
            unsetBatchComputeCache(key);
        }
    }

    // refreshPrimaryWorker = () => {
    //     if(this.refreshWorkerInterval){
    //         clearTimeout(this.refreshWorkerInterval);
    //     }
    //     this.refreshWorkerInterval = setTimeout(() => {
    //         if(this.workerInstance.terminate){
    //             this.workerInstance.terminate();
    //             this.workerInstance = null;
    //             this.initializePrimaryWebWorker(true);
    //         }
    //     }, 10000);
    // }

    // workerFunction = async (params) => {
    //     console.log('workerFunction triggered')
    //     if(!this.workerInstance){
    //         this.initializePrimaryWebWorker();
    //     }
    //     if(this.workerInstance){
    //         let stringified = {};
    //         let usedFunctions = JSONfn.stringify({
    //             historyPushFunction : this.historyPushFunction,
    //             setStateFunction : params['setStateFunction'],
    //             setElementAppData : this.setElementAppData,
    //             setParentAppData : this.setParentAppData
    //         })
    //         if(params['setStateFunction']){
    //             delete params['setStateFunction'];
    //         }
    //         let inhouzParentAppId = sessionStorage.getItem('inhouzParentAppId');
    //         let indexedDbSessionKey = sessionStorage.getItem('indexedDbSessionKey');
    //         stringified = {
    //             environment : this.state.appEnvironment,
    //             ...stringified,
    //             params,
    //             usedFunctions,
    //             indexDbName : inhouzParentAppId,
    //             indexDbPrefix : `${inhouzParentAppId}${this.props.indexDbProp || ''}`,
    //             indexedDbSessionKey
    //         }
    //         // console.log('stringified', stringified)
    //         // let size = sizeof(stringified)/1000;
    //         // console.log('size', size, this.state.pageSize)
    //         // this.setState({
    //         //     pageSize : this.state.pageSize + size
    //         // });
    //         let result = await this.workerInstance.expensive(stringified)
    //         .then(res => {
    //             return res;
    //         });
    //         stringified = null;
    //         params=null;
    //         return result;
    //     }
    // }

    historyPushFunction = (path='') => {
        this.props.history.push(path);
    }

    clearState = () => {
        const {appMap={}, setAppViewerAppObject} = this.props;
        const {appId=''} = this.state;
        if(appId && setAppViewerAppObject){
            let data = appMap[appId];
            if(data){
                setAppViewerAppObject({
                    appId,
                    data : {}
                });
            }
        }
    }

    setViewDimensions = () => {
        this.setState({
            viewHeight : window.innerHeight,
            viewWidth : window.innerWidth,
            stateChangeId : Math.random()
        })
    }

    updateTrackerField = (field='') => {
        const {
            setAppViewerAppObject
        } = this.props;
        const {appId=''} = this.state;
        if(setAppViewerAppObject){
            setAppViewerAppObject({
                appId,
                data : {
                    [field] : shortid.generate()
                }
            });
        }
    }

    updateRouteProps = () => {
        const {
            routeProps={}, setAppViewerAppObject,
            setViewerAppData
        } = this.props;
        const {appId=''} = this.state;

        if(setAppViewerAppObject && appId){
            let routeParams = {
                mappedQueryStrings : routeProps['queryStringParameters'] || {},
                mappedRouteParams : routeProps['routeParameters'] || {},
                route : routeProps['route']
            }
            setAppViewerAppObject({
                appId,
                data : routeParams
            });
        }

        if(setViewerAppData && appId){
            setViewerAppData(
                routeProps,
                appId,
                true
            );
        }
    }

    updateUserProps = () => {
        const {
            userProp={}, setViewerAppData
        } = this.props;
        const {appId=''} = this.state;
        if(setViewerAppData && appId){
            setViewerAppData(
                {
                    user : userProp
                },
                appId,
                true
            );
        }
    }

    parsePageLifeCycles = (method='', pageId='') => {
        if(!pageId){
            return;
        }
        const {
            appMap={}
        } = this.props;
        const appObj = appMap[this.state.appId] || {};
        const {
            appInFocus={}, appFunctionMap={},
            App_Data={}, 
            parsedPageMap={}, mappedRouteParams={}, mappedQueryStrings={}
        } = appObj;
        const {appSettings={}} = App_Data;
        const {user={}, userAgent={}} = appSettings;
        const {pageMap={}} = parsedPageMap;
        let slug = mappedRouteParams['slug'] || '';
        const page = pageMap[slug];
        if(page){
            const {pageData={}} = page;
            const {pageLifeCycleFunctions=[]} = pageData;
            for (let i = 0; i < pageLifeCycleFunctions.length; i++){
                let lifeCycleFunction = pageLifeCycleFunctions[i];
                let {
                    lifecycleMethod='', functionId='',
                    functionParameters={}
                } = lifeCycleFunction;
                if(
                    lifecycleMethod === method
                ){
                    functionParserWrapper({
                        functionId,
                        functionParameters,
                        setStateFunction : this.setAppDataFunction,
                        user,
                        userAgent,
                        app_data : App_Data,
                        companyId : appInFocus['companyId'],
                        functionMap : appFunctionMap,
                        otherParameters : {
                            routeParameters : mappedRouteParams,
                            queryStringParameters : mappedQueryStrings,
                            inhouz_reserved_parameters_xyzh : {
                                historyPushFunction : this.historyPushFunction, 
                                appEnvironment : this.state.appEnvironment,
                                setElementAppData : this.setElementAppData,
                                setParentAppData : this.setParentAppData
                            }
                        }
                    });
                }
            }
        }
    }

    parsePages = () => {
        const {
            appMap={}, setAppViewerAppObject, history={}
        } = this.props;
        const {appId=''} = this.state;
        const appObj = appMap[appId] || {};
        const {appInFocus={}, parsedPageMap={}} = appObj;
        const {appType=''} = appInFocus;
        if(
            appType === 'webApp' &&
            setAppViewerAppObject
        ){
            const {
                notFoundId='', pageMap={}
            } = parsedPageMap;
            const {location={}} = history;
            let skip = process.env.REACT_APP_ROUTER_PARAM_INDEX ? parseInt(process.env.REACT_APP_ROUTER_PARAM_INDEX) : 0
            let routeParamsList = location.pathname.split('/').slice(1 + skip);
            let slug = routeParamsList[0] || '';
            let page = pageMap[slug];
            if(!page){
                page = pageMap[notFoundId];
            }
            let pageInFocusId = page ? page['pageId'] : '';
            setAppViewerAppObject({
                appId,
                data : {
                    pageInFocusId
                }
            });
        }
    }

    extractRouteParameters = (injectedPageMap, pageNotFoundId) => {
        const {
            appMap={}, setAppViewerAppObject, 
            history={}, setViewerAppData,
            logAppVisit, isWebComponent=false
        } = this.props;
        const {appId=''} = this.state;
        const {location={}} = history;
        let mappedQueryStrings = queryString.parse(window.location.search);
        let skip = process.env.REACT_APP_ROUTER_PARAM_INDEX ? parseInt(process.env.REACT_APP_ROUTER_PARAM_INDEX) : 0
        let routeParamsList = location.pathname.split('/').slice(1 + skip);
        let slug = routeParamsList[0] || '';
        let pageParams = routeParamsList.slice(1);
        let mappedParams = {};
        const appObj = appMap[appId] || {};
        const {
            parsedPageMap={}, variationId='',
            appType='', App_Data={}, environment='', 
            appInFocus={}
        } = appObj;
        const {
            notFoundId='', pageMap={}
        } = parsedPageMap;

        let page = injectedPageMap ? injectedPageMap[slug] : pageMap[slug];
        if(!page){
            let id = pageNotFoundId || notFoundId;
            page = injectedPageMap ? injectedPageMap[id] : pageMap[id];
        }

        let pageData = page && page['pageData'];
        if(pageData && setAppViewerAppObject && setViewerAppData && logAppVisit){
            const {routeParameters=[]} = pageData;
            for (let i = 0; i < routeParameters.length; i++){
                let obj = routeParameters[i];
                let {routeParameterName=''} = obj;
                mappedParams[routeParameterName] = pageParams[i] || '';
            }
            let location = typeof window !== 'undefined' ? window.location : null;
            let routeObj = {};
            if(location){
                routeObj['route'] = {
                    host : location.host,
                    hostname : location.hostname,
                    pathname : location.pathname,
                    href : location.href,
                    protocol : location.protocol && location.protocol.split(':')[0]
                }
            }
            let obj = {
                mappedRouteParams : {
                    ...mappedParams,
                    slug
                },
                mappedQueryStrings,
                ...routeObj
            }
            if(injectedPageMap){
                return {
                    ...obj,
                    pageInFocusId : page['pageId']
                };
            }else{
                setAppViewerAppObject({
                    appId,
                    data : obj
                });
                setViewerAppData(
                    {
                        routeParameters : {
                            ...mappedParams,
                            slug
                        },
                        queryStringParameters : mappedQueryStrings,
                        routeHash : shortid.generate(),
                        ...routeObj
                    },
                    appId,
                    true
                );
            }

            const {appSettings={}} = App_Data;
            //log route visit
            let routeVisitData = {
                appId,
                deviceMode : appSettings['deviceMode'],
                appType,
                variationId,
                environment,
                domain : location.href,
                companyId : appInFocus['companyId'],
                requestQuery : mappedQueryStrings
            }
            let encryptedPayload = encryptDecrypt(JSON.stringify({
                payload : routeVisitData,
                expirationTimestamp : moment().add(30, 'seconds').unix() * 1000
            }), true);
            logAppVisit({encryptedPayload}, true);
        }
    }

    extractDeviceMode = (userAgent={}) => {
        let deviceType = 'desktop';
        if(userAgent['isDesktop']){
            return 'desktop';
        }

        if(userAgent['isMobile']){
            deviceType = 'mobile';
        }

        if(
            userAgent['isTablet'] || 
            userAgent['isAndroidTablet'] || 
            userAgent['isiPad']
        ){
            deviceType = 'tablet';
        }

        return deviceType;
    }

    decryptAndInitializeApp = () => {
        try{
            let {
                initializeAppViewerApp, isWebComponent=false,
                compressedBuild='', subscriberData={},
                setAppViewerReducer, dataParameter={},
                compressedWebComponent='', routeProps={},
                userProp={}, userAgentProp, environmentProp=''
            } = this.props;
            if(initializeAppViewerApp && setAppViewerReducer){
                let decompressed = LZUTF8.decompress(compressedWebComponent || compressedBuild, {
                    inputEncoding : 'Base64'
                });
                let parsedBuild = JSON.parse(decompressed);
                let {
                    appId='', 
                    extractedRouteParameters={},
                    environment='',
                    appInFocus={},
                    userAgent={},
                    serverData={},
                    activeSession=false,
                    parsedPageMap={}
                } = parsedBuild;
                let {pageMap={}} = parsedPageMap;
                let {initialState={}} = appInFocus;
                //initialize state
                let deviceMode = parsedBuild['deviceMode'] || this.extractDeviceMode(userAgentProp || userAgent);
                let routeParams = {}, appSettingsRouteParams={};
                if(isWebComponent){
                    let page = pageMap[''] || {};
                    routeParams['pageInFocusId'] = page['pageId'] || '';
                    routeParams = {
                        ...routeParams,
                        mappedQueryStrings : routeProps['queryStringParameters'] || {},
                        mappedRouteParams : routeProps['routeParameters'] || {},
                        route : routeProps['route'] || {}
                    }
                    appSettingsRouteParams = {
                        ...routeProps,
                        user : userProp, //snuck this in here. Not so pretty.
                        userAgent : userAgentProp
                    };
                }else{
                    routeParams = extractedRouteParameters;
                    appSettingsRouteParams = {
                        routeParameters : extractedRouteParameters['mappedRouteParams'],
                        queryStringParameters : extractedRouteParameters['mappedQueryStrings'],
                        route : extractedRouteParameters['route']
                    }
                }
                let appData = {
                    ...parsedBuild,
                    App_Data : {
                        ...initialState,
                        ...serverData,
                        ...dataParameter,
                        appSettings : {
                            user : {
                                ...subscriberData,
                                isAuthenticated : isEmpty(subscriberData) ? false : true
                            },
                            userAgent,
                            lang : 'default',
                            deviceMode,
                            routeHash : shortid.generate(),
                            ...appSettingsRouteParams,
                            environment : environmentProp || environment
                        }
                    },
                    isWebComponent,
                    ...routeParams,
                    elementAppData : {},
                    elementAppDataTracker : {},
                    clearElementAppDataTracker : {},
                    webComponentCache : {},
                    environment : environmentProp || environment
                }
                initializeAppViewerApp({
                    appId,
                    appData
                });
                setAppViewerReducer({
                    appDeviceMode : deviceMode,
                    userAgent
                });

                initializeAppViewerApp = null; 
                // isWebComponent=null;
                compressedBuild=null;
                subscriberData=null;
                setAppViewerReducer=null; 
                dataParameter=null;
                compressedWebComponent=null;
                routeProps=null;
                userProp=null; 
                userAgentProp=null;
                // environmentProp=null;
                decompressed = null;
                parsedBuild = null;
                extractedRouteParameters = null;
                appInFocus = null;
                userAgent = null;
                serverData = null;
                parsedPageMap = null;
                pageMap = null;
                initialState = null;
                // deviceMode = null;
                routeParams = null;
                appSettingsRouteParams = null;
                return {
                    appId, isWebComponent, 
                    appEnvironment : environmentProp || environment,
                    appStateInitialized : true,
                    stateChangeId : Math.random(),
                    appData,
                    activeSession,
                    lang : 'default'
                };
            }else{
                initializeAppViewerApp = null; 
                // isWebComponent=null;
                compressedBuild=null;
                subscriberData=null;
                setAppViewerReducer=null; 
                dataParameter=null;
                compressedWebComponent=null;
                routeProps=null;
                userProp=null; 
                userAgentProp=null;
                // environmentProp=null;
            }
        }catch(e){
            console.log(e);
            if(alert){
                alert('An error occured');
            }
        }
    }

    setUserData = () => {
        const {appId} = this.state;
        const {
            setViewerAppData, subscriberData={},
            setSubscriptionAuthReducer, logAppVisit
        } = this.props;
        if(setViewerAppData && setSubscriptionAuthReducer){
            setViewerAppData(
                {
                    user : {
                        ...subscriberData,
                        isAuthenticated : isEmpty(subscriberData) ? false : true
                    }
                },
                appId,
                true
            );
            if(!isEmpty(subscriberData)){
                let logTime = sessionStorage.getItem('nextVisitLogTime');
                let logVisit = !logTime || (logTime && (new Date().getTime() > Number(logTime)))
                if(logAppVisit && logVisit){
                    const {
                        assetOwnerCompanyId='', companyId='', subscriberType='',
                        subscriptionId='', subscriptionServiceId='', 
                        subscriptionServiceTierId='', environment=''
                    } = subscriberData;
                    if(subscriptionId){
                        let paylaodObj = {
                            payload : {
                                assetOwnerCompanyId,
                                companyId,
                                subscriptionId,
                                usageTimestamp : new Date().getTime(),
                                subscriptionServiceId,
                                subscriptionServiceTierId,
                                environment,
                                userType : subscriberType,
                                activity : 'web app load'
                            },
                            expirationTimestamp : moment().add(30, 'seconds').unix() * 1000
                        };
                        let encryptedPayload = encryptDecrypt(JSON.stringify(paylaodObj), true);
                        logAppVisit({encryptedPayload});
                        sessionStorage.setItem('nextVisitLogTime', `${moment().add(30, 'minutes').unix() * 1000}`)
                    }
                }
                setSubscriptionAuthReducer({
                    loginMode : false
                });
            }
        }
    }

    loadWebFonts = async () => {
        const {
            appMap={}
        } = this.props;
        const {
            appId=''
        } = this.state;
        let appObj = appMap[appId] || {};
        let {fontFamilyList=[]} = appObj;
        if(fontFamilyList.length > 0){
            let WebFont = await import('webfontloader');
            if(WebFont){
                WebFont.load({
                    google: {
                      families: fontFamilyList
                    }
                });
            }
        }
    }

    initializeAppState = () => {
        const {
            setAppViewerReducer, setViewerAppData,
            appMap={}, isWebComponent=false,
            parentDeviceMode=''
        } = this.props;
        const {
            appId=''
        } = this.state;
        let appObj = appMap[appId] || {};
        let {appInFocus={}, App_Data={}} = appObj;

        if(
            setViewerAppData && !isEmpty(appInFocus) && 
            setAppViewerReducer
        ){
            const {appSettings={}} = App_Data;
            let deviceMode;
            if(
                isWebComponent && 
                parentDeviceMode
            ){
                deviceMode = parentDeviceMode;
            }else{
                deviceMode = this.getDeviceMode(appInFocus);
            }
            cookies.set('deviceMode', deviceMode, {expires: 365});
            if(deviceMode !== appSettings['deviceMode']){
                setViewerAppData(
                    {
                        deviceMode
                    },
                    appId,
                    true
                )
                setAppViewerReducer({
                    appDeviceMode : deviceMode,
                    userAgent : UserAgent.parse(window.navigator.userAgent)
                });
            }
        }
    }

    parsableJSON = (string) => {
        try{
            return JSON.parse(string);
        }catch(e){
            console.log('/appViewer/parsableJSON catch error', e);
            return false;
        }
    }

    setAppDataFunction = (data={}, updateSettings=false) => {
        let {appId=''} = this.state;
        let {setViewerAppData} = this.props;
        if(appId && setViewerAppData){
            setViewerAppData(data, appId, updateSettings);
        }
        data = null;
        updateSettings = null;
        appId = null;
        setViewerAppData = null;
    }

    setElementAppData = (params={}) => {
        let {appId=''} = this.state;
        let {setViewerElementAppData} = this.props;
        if(setViewerElementAppData){
            setViewerElementAppData({
                ...params,
                appId
            })
        }

        params = null;
        appId = null;
        setViewerElementAppData = null;
    }

    setParentAppData = (data={}) => {
        if(
            this.props.setParentAppData && 
            typeof data === 'object' && 
            !Array.isArray(data)
        ){
            this.props.setParentAppData(data);
        }

        data = null;
    }

    triggerOnLoadLifecycleFunctions = (params={}) => {
        let method = 'onAppLoad';
        const {appObj={}} = params;
        let {
            appInFocus={}, App_Data={}, appFunctionMap={},
            mappedRouteParams={}, mappedQueryStrings={},
            environment
        } = appObj;
        const {appSettings={}} = App_Data;
        const {user={}, userAgent={}} = appSettings;

        if(
            appInFocus && 
            appInFocus['appLifeCycleFunctions']
        ){  
            let {appLifeCycleFunctions = []} = appInFocus;
            for (let i = 0; i < appLifeCycleFunctions.length; i++){
                let lifeCycleFunction = appLifeCycleFunctions[i];
                let {
                    lifecycleMethod='', functionId='',
                    functionParameters={}
                } = lifeCycleFunction;
                if(
                    lifecycleMethod === method
                ){
                    functionParserWrapper({
                        functionId,
                        functionParameters,
                        setStateFunction : this.setAppDataFunction,
                        user,
                        userAgent,
                        app_data : App_Data,
                        companyId : appInFocus['companyId'],
                        functionMap : appFunctionMap,
                        otherParameters : {
                            routeParameters : mappedRouteParams,
                            queryStringParameters : mappedQueryStrings,
                            inhouz_reserved_parameters_xyzh : {
                                historyPushFunction : this.historyPushFunction, 
                                appEnvironment : environment,
                                setElementAppData : this.setElementAppData,
                                setParentAppData : this.setParentAppData
                            }
                        }
                    });
                }
            }
        }
    }

    triggerLifecycleFunctions = (method) => {
        let {
            appMap={}
        } = this.props;
        let appObj = appMap[this.state.appId] || {};
        let {
            appInFocus={}, App_Data={}, appFunctionMap={},
            //mappedRouteParams={}, mappedQueryStrings={},
        } = appObj;
        let {appSettings={}} = App_Data;
        let {
            user={}, userAgent={},
            routeParameters={}, queryStringParameters={}
        } = appSettings;

        if(
            appInFocus && 
            appInFocus['appLifeCycleFunctions']
        ){  
            let {appLifeCycleFunctions = []} = appInFocus;
            for (let i = 0; i < appLifeCycleFunctions.length; i++){
                let lifeCycleFunction = appLifeCycleFunctions[i];
                let {
                    lifecycleMethod='', functionId='',
                    functionParameters={}
                } = lifeCycleFunction;
                if(
                    lifecycleMethod === method
                ){
                    functionParserWrapper({
                        functionId,
                        functionParameters,
                        setStateFunction : this.setAppDataFunction,
                        user,
                        userAgent,
                        app_data : App_Data,
                        companyId : appInFocus['companyId'],
                        functionMap : appFunctionMap,
                        otherParameters : {
                            routeParameters,
                            queryStringParameters,
                            inhouz_reserved_parameters_xyzh : {
                                historyPushFunction : this.historyPushFunction, 
                                appEnvironment : this.state.appEnvironment,
                                setElementAppData : this.setElementAppData,
                                setParentAppData : this.setParentAppData
                            }
                        }
                    });

                    if(method === 'onAppLoad'){
                        this.setState({
                            appLoadLifecycleTriggered : true,
                            stateChangeId : Math.random()
                        })
                    }
                }

                lifeCycleFunction= null;
                lifecycleMethod = null;
                functionParameters = null;
                functionId = null;
            }
        }

        appMap = null;
        appObj= null;
        appInFocus = null;
        App_Data = null;
        appFunctionMap = null;
        appSettings= null;
        user= null;
        userAgent = null;
        routeParameters = null;
        queryStringParameters = null;
    }

    triggerStateUpdateLifecycleFunctions = (data={}, prevData={}) => {
        if(
            navigator.userAgent.indexOf('MSIE') !== -1
            || navigator.appVersion.indexOf('Trident/') > -1
        ){
            this.triggerStateUpdateLifecycleFunctionsNonWorker(data, prevData);
        }else{
            this.triggerStateUpdateLifecycleFunctionsWorker(data, prevData);
        }

        data = null;
        prevData = null;
    }

    triggerStateUpdateLifecycleFunctionsNonWorker = (data={}, prevData={}) => {
        console.log('lifecycle function (non worker) - onAppDataChange');
        let {
            pageDataChangeLifecycleFunctionMap={}, dataChangeLifecycleFunctions=[],
            dataChangeFunctionsExtracted=false
        } = this.state;
        let {
            appMap={}
        } = this.props;
        let appObj = appMap[this.state.appId] || {};
        let {
            appInFocus={}, appFunctionMap={}, pageInFocusId=''
            //mappedRouteParams={}, mappedQueryStrings={},
        } = appObj;
        let {appLifeCycleFunctions = []} = appInFocus;
        let lifecycleFunctions = dataChangeFunctionsExtracted ? 
        appInFocus['appType'] === 'webApp' ?
        pageDataChangeLifecycleFunctionMap[pageInFocusId]
        :
        dataChangeLifecycleFunctions
        :
        appLifeCycleFunctions.filter(obj => {
            return obj['lifecycleMethod'] === 'onStateUpdate';
        });

        if(lifecycleFunctions.length === 0){
            return;
        }
        let {appSettings={}} = data;
        let {
            user={}, userAgent={}, 
            routeParameters={}, queryStringParameters={}
        } = appSettings;
        
        for (let i = 0; i < lifecycleFunctions.length; i++){
            let lifeCycleFunction = lifecycleFunctions[i];
            let {
                functionId='',
                functionParameters={}, triggerField=''
            } = lifeCycleFunction;
            let functionObj = appFunctionMap[functionId];
            if(
                // lifecycleMethod === 'onStateUpdate' &&
                functionObj
            ){
                let current = dotNotationMapper(data, triggerField, true);
                let previous = dotNotationMapper(prevData, triggerField, true);
                if(!isEqual(current, previous)){
                    functionParserWrapper({
                        functionId,
                        functionParameters,
                        setStateFunction : this.setAppDataFunction,
                        user,
                        userAgent,
                        app_data : data,
                        companyId : appInFocus['companyId'],
                        functionMap : appFunctionMap,
                        otherParameters : {
                            routeParameters,
                            queryStringParameters,
                            inhouz_reserved_parameters_xyzh : {
                                historyPushFunction : this.historyPushFunction, 
                                appEnvironment : this.state.appEnvironment,
                                setElementAppData : this.setElementAppData,
                                setParentAppData : this.setParentAppData
                            }
                        }
                    });
                }
                current = null;
                previous = null;
            }
            lifeCycleFunction = null;
            functionId = null;
            functionParameters = null;
            triggerField = null;
            functionObj = null;
        }

        lifecycleFunctions = null;
        data = null;
        prevData = null;
        pageDataChangeLifecycleFunctionMap = null;
        dataChangeLifecycleFunctions = null;
        dataChangeFunctionsExtracted = null;
        appMap = null;
        appObj = null;
        appInFocus = null;
        appFunctionMap = null;
        pageInFocusId = null;
        appLifeCycleFunctions = null;
        appSettings = null;
        user= null;
        userAgent = null;
        routeParameters = null;
        queryStringParameters = null;
    }

    triggerStateUpdateLifecycleFunctionsWorker = async (data={}, prevData={}) => {
        console.log('lifecycle function (with worker) - onAppDataChange');
        if(!this.appDataChangeWorkerInstance){
            this.initializeAppDataChangeWebWorker();
        }
        if(this.appDataChangeWorkerInstance){
            let {
                pageDataChangeLifecycleFunctionMap={}, dataChangeLifecycleFunctions=[],
                dataChangeFunctionsExtracted=false
            } = this.state;
            let {
                appMap={}
            } = this.props;
            let appObj = appMap[this.state.appId] || {};
            let {
                appInFocus={}, appFunctionMap={}, pageInFocusId=''
            } = appObj;
            let {appLifeCycleFunctions = []} = appInFocus;
            let lifecycleFunctions = dataChangeFunctionsExtracted ?
            appInFocus['appType'] === 'webApp' ?
            pageDataChangeLifecycleFunctionMap[pageInFocusId]
            :
            dataChangeLifecycleFunctions
            :
            appLifeCycleFunctions.filter(obj => {
                return obj['lifecycleMethod'] === 'onStateUpdate';
            });

            if(lifecycleFunctions.length === 0){
                return;
            }

            let inhouzParentAppId = sessionStorage.getItem('inhouzParentAppId');
            let dbKey = `${inhouzParentAppId}_${Math.random()}`;
            await indexedDbSetItem({
                key : dbKey,
                data : {
                    appLifeCycleFunctions : lifecycleFunctions,
                    App_Data : data,
                    prevData
                }
            });
            await this.appDataChangeWorkerInstance.getFunctionsToRun({
                key : dbKey
            });
            let functToRun = await indexedDbGetItem({
                key : dbKey
            });
            indexedDbClear({keysToDelete : [dbKey]});
            let {
                hasFunctions=false, functionsToRun=[]
            } = functToRun;

            if(hasFunctions){
                let {appSettings={}} = data;
                let {
                    user={}, userAgent={}, 
                    routeParameters={}, queryStringParameters={}
                } = appSettings;
                for (let i = 0; i < functionsToRun.length; i++){
                    let lifeCycleFunction = functionsToRun[i];
                    let {
                        functionId='',
                        functionParameters={},
                    } = lifeCycleFunction;

                    functionParserWrapper({
                        functionId,
                        functionParameters,
                        setStateFunction : this.setAppDataFunction,
                        user,
                        userAgent,
                        app_data : data,
                        companyId : appInFocus['companyId'],
                        functionMap : appFunctionMap,
                        otherParameters : {
                            routeParameters,
                            queryStringParameters,
                            inhouz_reserved_parameters_xyzh : {
                                historyPushFunction : this.historyPushFunction, 
                                appEnvironment : this.state.appEnvironment,
                                setElementAppData : this.setElementAppData,
                                setParentAppData : this.setParentAppData
                            }
                        }
                    });
                    lifeCycleFunction = null;
                    functionId = null;
                    functionParameters = null;
                }

                appSettings = null;
                user = null;
                userAgent = null;
                routeParameters = null;
                queryStringParameters = null;
            }

            functToRun = null;
            functionsToRun = null;
            lifecycleFunctions = null;
            lifecycleFunctions = null;
            pageDataChangeLifecycleFunctionMap = null;
            dataChangeLifecycleFunctions = null;
            dataChangeFunctionsExtracted = null;
            appMap = null;
            appObj = null;
            appInFocus = null;
            appFunctionMap = null;
            pageInFocusId = null;
            appLifeCycleFunctions = null;
            inhouzParentAppId = null;
            dbKey = null;
            hasFunctions = null;
        }

        data = null;
        prevData = null;
    }

    getDeviceMode = (appInFocus={}) => {
        const {settings = {}} = appInFocus;
        const {screenWidth={}} = settings;
        let windowWidth = window.innerWidth;
        if(
            windowWidth >= (screenWidth['desktop'] && screenWidth['desktop']['greaterThan'])
        ){
            return 'desktop'
        }
        if(
            windowWidth <= (screenWidth['mobile'] && screenWidth['mobile']['lessThan'])
        ){
            return 'mobile'
        }
        if(
            (
                windowWidth <= (screenWidth['tablet'] && screenWidth['tablet']['lessThan'])
            ) && 
            (
                windowWidth >= (screenWidth['tablet'] && screenWidth['tablet']['greaterThan'])
            )
        ){
            return 'tablet';
        }else{
            return 'mobileLandscape';
        }
    }

    handleResize = (e, refresh) => {
        const {
            appMap={}, setAppViewerReducer,
            isWebComponent='', parentDeviceMode=''
        } = this.props;
        const {appId=''} = this.state;
        if(setAppViewerReducer && appId){
            const appObj = appMap[appId] || {};
            const {appInFocus={}} = appObj;
            let deviceMode;
            if(
                isWebComponent && 
                parentDeviceMode
            ){
                deviceMode = parentDeviceMode;
            }else{
                deviceMode = this.getDeviceMode(appInFocus);
            }
            setViewerAppData(
                {
                    deviceMode
                },
                appId,
                true
            );
            setAppViewerReducer({
                appDeviceMode : deviceMode
            });
            if(refresh){
                this.setState({
                    appId : '',
                    stateChangeId : Math.random()
                }, () => {
                    let timerHolder = setTimeout(() => {
                        this.setState({
                            appId,
                            stateChangeId : Math.random()
                        })

                        clearTimeout(timerHolder);
                    }, 50);
                })
            }
        }
        this.setViewDimensions();
    }

    render(){
        const {
            isWebComponent=false, appId='',
            viewHeight=0, viewWidth=0, appStateInitialized=false,
            appEnvironment='', activeSession=false
        } = this.state;
        if(appId && appStateInitialized){
            return (
                <React.Fragment>
                    <ViewTypeParser 
                        isWebComponent={isWebComponent}
                        appId={appId}
                        setAppDataFunction={this.setAppDataFunction}
                        setElementAppData={this.setElementAppData}
                        setParentAppData={isWebComponent ? this.setParentAppData : null}
                        viewWidth={viewWidth}
                        viewHeight={viewHeight}
                        historyPushFunction={this.historyPushFunction}
                        // handleCachedAttributeCleanup={this.handleCachedAttributeCleanup}
                        appEnvironment={appEnvironment}
                        workerInstance={this.workerFunction}
                        bulkAttributeWorkerInstance={this.bulkAttributeWorkerFunction}
                        workerInstanceProp={this.props.workerInstanceProp}
                        bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                        websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                        webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                        appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                        calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                        appLanguage={this.props.appLanguage || this.state.lang}
                        // workerInstanceProp={this.props.workerInstanceProp || this.getPrimaryWorkerInstance}
                        // websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp || this.getWebsiteResourceWorkerInstance}
                        // webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp || this.getWebComponentWorkerInstance}
                        // calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp || this.getListUpdateTrackerWorkerInstance}
                        activeSession={activeSession}
                    />
                </React.Fragment>
            )
        }
        return null; 
    }
}

function mapStateToProps(state){
    return {
        compressedBuild : state.AppViewer.compressedBuild,
        appMap : state.AppViewer.appMap,
        userAgent : state.AppViewer.userAgent,
        appDeviceMode : state.AppViewer.appDeviceMode,
        subscriberData : state.SubscriptionAuth.subscriberData,
        subcriberDataLoading: state.SubscriptionAuth.subcriberDataLoading
    }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({
        setAppViewerReducer, setViewerAppData,
        initializeAppViewerApp, setAppViewerAppObject,
        subscriptionGetUser, setSubscriptionAuthReducer,
        logAppVisit, getWebsiteResources, setViewerElementAppData,
        setBatchComputeCache, unsetBatchComputeCache
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AppViewer));