import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    setAppViewerAppObject, setSubscriptionAuthReducer
} from '../../../actions';
import {isEmpty, isEqual} from 'lodash';
import elementAttributeFunction from './utils/functions/attributes/elementAttributeFunction';
import dotNotationMapper from '../../../utils/data/dotNotationMapper';
import RecursiveViewerElementUi from './elementUi/recursiveViewerElementUi';
import SubscriptionLoginBase from '../subscriptionLogin/subscriptionLoginBase';
import encryptDecrypt from '../../../utils/cryptography/encryptDecrypt';
import AppPasswordValidation from '../passwordValidation/appPasswordValidation';
import MetaTagsComponent from './metaTagsComponent';
import functionParserWrapper from '../../../utils/functionParser/functionParserWrapper';

class WebAppPageViewer extends Component{
    constructor(props){
        super(props);
        this.state = {
            visibleModalIds : [],
            pageChange : false,
            pageChangeCount : 0,
            stateChangeId : Math.random(),
        }
    }
    intervalState;

    componentDidMount(){
        const {
            appMap={}, appId=''
        } = this.props;
        this.checkCachedPassword();
        let timerHolder = setTimeout(() => {
            this.parsePageLifeCycles('onPageLoad', appMap[appId] && appMap[appId]['pageInFocusId']);
            clearTimeout(timerHolder);
        }, 50);
    }

    componentDidUpdate(prevProps){
        const {
            appMap, appId
        } = this.props;
        if(
            !isEqual(
                (appMap[appId] && appMap[appId]['appDataChangeTracker']),
                (
                    prevProps['appMap'] && prevProps['appMap'][appId] && 
                    prevProps['appMap'][appId]['appDataChangeTracker']
                )
            )
        ){
            this.getVisibleModalInterval();
            this.updatePageCount();
        }

        if(
            !isEqual(
                (appMap[appId] && appMap[appId]['pageInFocusId']),
                (
                    prevProps['appMap'] && prevProps['appMap'][appId] && 
                    prevProps['appMap'][appId]['pageInFocusId']
                )
            )
        ){
            this.forcePageSwitch();
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        const {
            appMap, appId, viewHeight, viewWidth,
            subcriberDataLoading, loginMode,
            subscriberData
        } = this.props;

        if(
            this.state['stateChangeId'] !== nextState['stateChangeId'] ||
            !isEqual(
                (appMap[appId] && appMap[appId]['appDataChangeTracker']),
                (
                    nextProps['appMap'] && nextProps['appMap'][appId] && 
                    nextProps['appMap'][appId]['appDataChangeTracker']
                )
            ) ||
            !isEqual(
                (
                    appMap[appId] && appMap[appId]['pageInFocusId']),
                (
                    nextProps['appMap'] && nextProps['appMap'][appId] && 
                    nextProps['appMap'][appId]['pageInFocusId']
                )
            ) ||
            !isEqual(viewHeight, nextProps.viewHeight) ||
            !isEqual(viewWidth, nextProps.viewWidth) ||
            !isEqual(subcriberDataLoading, nextProps.subcriberDataLoading) ||
            !isEqual(loginMode, nextProps.loginMode) ||
            !isEqual(subscriberData, nextProps.subscriberData)
        ){
            return true;
        }else{
            return false;
        }
    }

    componentWillUnmount(){
        if(this.intervalState){
            clearTimeout(this.intervalState);
        }
    }

    forcePageSwitch = () => {
        const {pageChangeCount=0} = this.state;
        this.setState({
            pageChange : true,
            pageChangeCount : pageChangeCount + 1,
            stateChangeId : Math.random(),
        }, () => {
            let timerHolder = setTimeout(() => {
                this.setState({
                    pageChange : false,
                    stateChangeId : Math.random(),
                }, () => {
                    const {
                        appMap={}, appId=''
                    } = this.props;
                    let timerHolderX = setTimeout(() => {
                        this.parsePageLifeCycles('onPageLoad', appMap[appId] && appMap[appId]['pageInFocusId']);
                        clearTimeout(timerHolderX);
                    }, 50);
                })
                clearTimeout(timerHolder);
            }, 1); //1
        })
    }

    parsePageLifeCycles = (method='', pageId='') => {
        if(!pageId){
            return;
        }
        const {
            appMap={}, appId='', setElementAppData,
            setParentAppData
        } = this.props;
        const appObj = appMap[appId] || {};
        const {
            appInFocus={}, appFunctionMap={},
            App_Data={}, 
            parsedPageMap={}, mappedRouteParams={}, mappedQueryStrings={}
        } = appObj;
        const {appSettings={}} = App_Data;
        const {user={}, userAgent={}} = appSettings;
        const {pageMap={}} = parsedPageMap;
        let slug = mappedRouteParams['slug'] || '';
        const page = pageMap[slug];
        if(page){
            const {pageData={}} = page;
            const {pageLifeCycleFunctions=[]} = pageData;
            for (let i = 0; i < pageLifeCycleFunctions.length; i++){
                let lifeCycleFunction = pageLifeCycleFunctions[i];
                let {
                    lifecycleMethod='', functionId='',
                    functionParameters={}
                } = lifeCycleFunction;
                if(
                    lifecycleMethod === method
                ){
                    functionParserWrapper({
                        functionId,
                        functionParameters,
                        setStateFunction : this.props.setAppDataFunction,
                        user,
                        userAgent,
                        app_data : App_Data,
                        companyId : appInFocus['companyId'],
                        functionMap : appFunctionMap,
                        otherParameters : {
                            routeParameters : mappedRouteParams,
                            queryStringParameters : mappedQueryStrings,
                            inhouz_reserved_parameters_xyzh : {
                                historyPushFunction : this.props.historyPushFunction, 
                                appEnvironment : this.props.appEnvironment,
                                setElementAppData,
                                setParentAppData
                            }
                        }
                    });
                }
            }
        }
    }

    updatePageCount = () => {
        const {pageChangeCount=0} = this.state;
        if(!pageChangeCount){
            this.setState({
                pageChangeCount : pageChangeCount + 1,
                stateChangeId : Math.random(),
            });
        }
    }

    getVisibleModalInterval = async () => {
        if(this.intervalState){
            clearTimeout(this.intervalState);
        }
        this.intervalState = setTimeout(() => {
            this.extractVisibleModals();
            clearTimeout(this.intervalState);
        }, 5)
    }

    checkCachedPassword = () => {
        try{
            const {
                appMap={}, appId='', appEnvironment='',
                setSubscriptionAuthReducer
            } = this.props;
            const appObj = appMap[appId] || {};
            const {
                appProtected=false,
                appInFocus={}
            } = appObj;
            if(appProtected && setSubscriptionAuthReducer){
                const {settings={}, companyId=''} = appInFocus;
                const {
                    appRestrictionType='', passwordSet=false,
                } = settings;
                if(passwordSet && appRestrictionType === 'password'){
                    //check if session data exists
                    let encryptedData = sessionStorage.getItem('x-java');
                    if(encryptedData){
                        let decrypted = encryptDecrypt(
                            encryptedData,
                            false
                        );
                        let parsed = JSON.parse(decrypted);
                        if(parsed['expirationTimestamp'] > new Date().getTime()){
                            let payload = parsed['payload'];
                            if(
                                (payload['appId'] === appId) && 
                                (payload['environment'] === appEnvironment) && 
                                (payload['assetOwnerCompanyId'] === companyId)
                            ){
                                setSubscriptionAuthReducer({
                                    subscriberData : {
                                        isAuthenticated : true
                                    }
                                });
                            }
                        }
                    }
                }
            }
        }catch(e){
            console.log('/webAppPageViewer checkCachedPassword catch error', e);
            return;
        }
    }

    extractVisibleModals = () => {
        const {
            appMap={}, appId='', setAppDataFunction,
            historyPushFunction, appEnvironment,
            setElementAppData, setParentAppData
        } = this.props;
        const appObj = appMap[appId] || {};
        const {
            modalElementIds=[], App_Data={}, appElementsMap={},
            appInFocus={}, mappedRouteParams={}, 
            mappedQueryStrings={},
            appFunctionMap
        } = appObj;
        const {appSettings={}} = App_Data;
        const {user={}, userAgent={}} = appSettings;

        let visbileList = [];
        for (let i = 0; i < modalElementIds.length; i++){
            let elementId = modalElementIds[i];
            let element = appElementsMap[elementId];
            if(element && setAppDataFunction){
                let toggleField = '';
                if(
                    element['attributes'] && 
                    element['attributes']['modalToggleField']
                ){
                    toggleField = element['attributes']['modalToggleField'];
                }else{
                    let otherParameters = {
                        // inList,
                        // listItemIndex,
                        // listItemData,
                        routeParameters : mappedRouteParams,
                        queryStringParameters : mappedQueryStrings,
                        inhouz_reserved_parameters_xyzh : {
                            historyPushFunction,
                            appEnvironment,
                            setElementAppData,
                            setParentAppData
                        }
                    }
                    let parsedAttributes = elementAttributeFunction({
                        element,
                        functionMap : appFunctionMap,
                        App_Data,
                        user,
                        userAgent,
                        setStateFunction : setAppDataFunction,
                        companyId : appInFocus['companyId'],
                        otherParameters
                    }); 
                    let {
                        modalToggleField=''
                    } = parsedAttributes;
                    toggleField = modalToggleField;
                }
                
                let toggleState = dotNotationMapper(App_Data, toggleField);
                if(
                    typeof toggleState === 'boolean' && 
                    toggleState
                ){
                    visbileList.push(elementId);
                }
            }
        }

        this.setState({
            visibleModalIds : visbileList,
            stateChangeId : Math.random(),
        });
    }

    renderModals = () => {
        const {
            visibleModalIds=[]
        } = this.state;
        const {
            historyPushFunction, workerInstance,
            appEnvironment, appId='', setAppDataFunction,
            setElementAppData, setParentAppData, appMap={}
        } = this.props;
        const appObj = appMap[appId] || {};
        const {appElementsMap={}} = appObj;

        return (
            <div>
                {
                   visibleModalIds.map((id, i) => {
                       return <RecursiveViewerElementUi 
                            key={i}
                            elementId={id}
                            element={appElementsMap[id]}
                            parentElementId=''
                            appId={appId}
                            workerInstance={workerInstance}
                                    bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                            workerInstanceProp={this.props.workerInstanceProp}
                        bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                        handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                            websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                            webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                            appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                            calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                            appLanguage={this.props.appLanguage}
                            historyPushFunction={historyPushFunction}
                            appEnvironment={appEnvironment}
                            setAppDataFunction={setAppDataFunction}
                            setElementAppData={setElementAppData}
                            setParentAppData={setParentAppData}
                        />
                   })
                }
            </div>
        )
    }

    render(){
        const {
            appId='', setAppDataFunction,
            appMap={}, isWebComponent=false,
            pageId='', workerInstance, activeSession=false,
            historyPushFunction, appEnvironment,
            appDeviceMode='', subcriberDataLoading=false,
            subscriberData={}, loginMode, passwordValidationLoading=false,
            setElementAppData, setParentAppData
        } = this.props;
        const {pageChange=false, pageChangeCount=0} = this.state;
        const appObj = appMap[appId] || {};
        const {
            pageInFocusId='',
            pageRootElementIdMap={},
            appProtected=false,
            mappedRouteParams={},
            parsedPageMap={},
            appInFocus={},
            App_Data={},
            appElementsMap={},
            companyType=''
        } = appObj;
        let id = pageId || pageInFocusId;
        let pageInFocusElementId = pageRootElementIdMap[id] || '';
        let pageInFocus = appElementsMap[pageInFocusElementId] || {};
        let style={
            padding : 0,
            margin : 0,
            width : '100%',
            height : '100%'
        };
        let pageMap = parsedPageMap['pageMap'] || {}
        let slug = mappedRouteParams['slug'] || '';
        let page = pageMap[slug] || {};
        
        const {settings={}} = appInFocus;
        const {
            appRestrictionType=''
        } = settings;
        let isPageProtected = page['protected'] ? true : false;
        let windowExists = typeof window !== 'undefined';
        if(!isWebComponent){
            style={
                ...style,
                minHeight : windowExists ? window.innerHeight : '100%',
                minWidth : windowExists ? window.innerWidth : '100%',
            }
        }

        if(!pageChange){
            return (
                <div
                    style={style}
                >
                    {
                        (
                            !appProtected ||
                            (
                                appProtected && 
                                !isPageProtected && 
                                !loginMode
                            ) ||
                            (
                                appProtected && 
                                isPageProtected && 
                                (
                                    !isEmpty(subscriberData) ||
                                    activeSession
                                )
                            )
                        ) && 
                        <React.Fragment>
                            {
                                pageChangeCount > 0 && 
                                <MetaTagsComponent 
                                    page={page}
                                    App_Data={App_Data}
                                    appEnvironment={appEnvironment}
                                    historyPushFunction={historyPushFunction}
                                    appId={appId}
                                    appMap={appMap}
                                />
                            }
                            {
                                pageInFocus['elementId'] && 
                                <RecursiveViewerElementUi 
                                    elementId={pageInFocus['elementId'] || ''}
                                    element={pageInFocus}
                                    parentElementId=''
                                    setAppDataFunction={setAppDataFunction}
                                    setElementAppData={setElementAppData}
                                    setParentAppData={setParentAppData}
                                    appId={appId}
                                    workerInstance={workerInstance}
                                    bulkAttributeWorkerInstance={this.props.bulkAttributeWorkerInstance}
                                    workerInstanceProp={this.props.workerInstanceProp}
                                    bulkAttributeWorkerInstanceProp={this.props.bulkAttributeWorkerInstanceProp}
                                    handleCachedAttributeCleanup={this.props.handleCachedAttributeCleanup}
                                    websiteResourceWorkerInstanceProp={this.props.websiteResourceWorkerInstanceProp}
                                    webComponentWorkerInstanceProp={this.props.webComponentWorkerInstanceProp}
                                    appDataChangeWorkerInstanceProp={this.props.appDataChangeWorkerInstanceProp}
                                    calculateListKeyTrackerWorkerInstanceProp={this.props.calculateListKeyTrackerWorkerInstanceProp}
                                    appLanguage={this.props.appLanguage}
                                    appEnvironment={appEnvironment}
                                    historyPushFunction={historyPushFunction}
                                />
                            }
                            {this.renderModals()}
                        </React.Fragment>
                    }
                    {
                        appProtected && 
                        (
                            isPageProtected ||
                            loginMode
                        ) && 
                        appRestrictionType === 'subscription' && 
                        // !subcriberDataLoading &&
                        isEmpty(subscriberData) &&
                        !activeSession &&
                        <div
                            className='d-flex align-items-center justify-content-center'
                            style={{
                                height : '100vh',
                                width : '100vw',
                                background : process.env.RAZZLE_GRAY_BACKGROUND,
                            }}
                        >
                            <SubscriptionLoginBase 
                                displayMode={appDeviceMode}
                                environment={appEnvironment}
                                assetOwnerCompanyId={appInFocus['companyId']}
                                assetId={appInFocus['appId']}
                            />
                        </div>
                    }
                    {
                        appProtected && 
                        (
                            isPageProtected ||
                            loginMode
                        ) && 
                        appRestrictionType === 'password' && 
                        // !subcriberDataLoading &&
                        isEmpty(subscriberData) &&
                        <div
                            className='d-flex align-items-center justify-content-center'
                            style={{
                                height : '100vh',
                                width : '100vw',
                                background : process.env.RAZZLE_GRAY_BACKGROUND,
                            }}
                        >
                            <AppPasswordValidation 
                                displayMode={appDeviceMode}
                                environment={appEnvironment}
                                assetOwnerCompanyId={appInFocus['companyId']}
                                assetId={appInFocus['appId']}
                                destination='app'
                            />
                        </div>
                    }
                    {
                        companyType === 'freelancer' && 
                        <a
                            className='d-flex align-items-center'
                            style={{
                                position : 'fixed',
                                bottom : '10px',
                                left : '10px',
                                zIndex : 100000000000,
                                borderRadius : '3px',
                                overflow : 'hidden',
                                textDecoration : 'none',
                                padding : '3px 4px',
                                display : 'inline',
                                background : 'black',
                                color : 'white',
                                fontWeight : 'bold',
                                cursor : 'pointer',
                                boxShadow : '0px 1px 5px 1px rgba(0,0,0,0.47)',
                                WebkitBoxShadow : '0px 1px 5px 1px rgba(0,0,0,0.47)',
                                MozBoxShadow : '0px 1px 5px 1px rgba(0,0,0,0.47)'
                            }}
                            href='https://www.inhouzcloud.io'
                            target='_blank'
                            title='Built on Inhouz Cloud'
                        >
                            <img 
                                style={{
                                    width : '20px'
                                }}
                                src='https://storage.googleapis.com/inhouz_website/company_logos/Inhouz_Logo_White_Min.png'
                            />
                            <div
                                className='ml-1'
                                style={{
                                    fontSize : '0.7rem'
                                }}
                            >
                                Inhouz Cloud
                            </div> 
                        </a>
                    }
                </div>
            )
        }

        return null;
    }
}

function mapStateToProps(state){
    return {
        appMap : state.AppViewer.appMap,
        appDeviceMode : state.AppViewer.appDeviceMode,
        user : state.SubscriptionAuth.subscriberData,
        subcriberDataLoading : state.SubscriptionAuth.subcriberDataLoading,
        subscriberData : state.SubscriptionAuth.subscriberData,
        loginMode : state.SubscriptionAuth.loginMode,
        passwordValidationLoading : state.SubscriptionAuth.passwordValidationLoading
    }
}

export default connect(mapStateToProps, {
    setAppViewerAppObject, setSubscriptionAuthReducer
})(WebAppPageViewer);