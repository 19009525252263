import simpleScriptParser from './simpleScript/simpleScriptParser';
import setMappedVariable from './mappingFunction/setMappedVariable';
import ifElseParser from './functionTypeParser/ifElseParser';
import forLoopParser from './functionTypeParser/forLoopParser';
import whileLoopParser from './functionTypeParser/whileLoopParser';
import switchParser from './functionTypeParser/switchParser';
import resolveExpression from './simpleScript/expressionParser/resolveExpression';
import dbOpsParser from './functionTypeParser/dbOpsParser';
import networkRequestParser from './functionTypeParser/networkRequestParser';
import consoleLogParser from './functionTypeParser/consoleLogParser';
import secretKeyParser from './functionTypeParser/secretKeyParser';
import createScheduledTaskParser from './functionTypeParser/createScheduledTaskParser';
import setTimeoutParser from './functionTypeParser/setTimeoutParser';
import base64Parse from './functionTypeParser/base64Parse';
import parseCrypto from './functionTypeParser/parseCrypto';
import browserStorageParser from './functionTypeParser/browserStorageParser';
import objectAssignParser from './functionTypeParser/objectAssignParser';
import idParser from './functionTypeParser/idParser';
import windowLocationParser from './functionTypeParser/windowLocationParser';
import fileToBase64Parser from './functionTypeParser/fileToBase64Parser';
import xmlToJsonParser from './functionTypeParser/xmlToJsonParser';
import jsonToXmlParser from './functionTypeParser/jsonToXmlParser';
import stringToArrayBufferParser from './functionTypeParser/stringToArrayBufferParser';
import arrayBufferToBase64Parser from './functionTypeParser/arrayBufferToBase64Parser';
import jsonToCsvParser from './functionTypeParser/jsonToCsvParser';
import compressDecompressParser from './functionTypeParser/compressDecompressParser';
import objectSizeParser from './functionTypeParser/objectSizeParser';
import imageResizeParser from './functionTypeParser/imageResizeParser';
import getDomProperyParser from './functionTypeParser/getDomProperyParser';
import setDomPropertyParser from './functionTypeParser/setDomPropertyParser';
import eventBasicMethodParser from './functionTypeParser/eventBasicMethodParser';
import executeExternalFunctionParser from './functionTypeParser/executeExternalFunctionParser';
import navigationParser from './functionTypeParser/navigationParser';
import getEnvironmentParser from './functionTypeParser/getEnvironmentParser';
// import setCookieParser from './functionTypeParser/setCookieParser';
// import setResponseStatusParser from './functionTypeParser/setResponseStatusParser';
import sqlDbParser from './functionTypeParser/sqlDbParser';
import mongoDbParser from './functionTypeParser/mongoDbParser';
import redisParser from './functionTypeParser/redisParser';
import awsS3Parser from './functionTypeParser/awsS3Parser';
import remoteNetworkRequestParser from './functionTypeParser/remoteNetworkRequestParser';
import elementSetStateParser from './functionTypeParser/elementSetStateParser';
import updateParentAppDataParser from './functionTypeParser/updateParentAppDataParser';
import jwtSignParser from './functionTypeParser/jwtSignParser';
import jwtVerifyParser from './functionTypeParser/jwtVerifyParser';
import jwkToPemParser from './functionTypeParser/jwkToPemParser';
import getCurrentPositionParser from './functionTypeParser/getCurrentPositionParser';
import watchPositionParser from './functionTypeParser/watchPositionParser';
// import manageSubscriptionUserRestrictionParser from './functionTypeParser/manageSubscriptionUserRestrictionParser';
// import getSubscribedTenantParser from './functionTypeParser/getSubscribedTenantParser';
// import getSubscribedTenantCountParser from './functionTypeParser/getSubscribedTenantCountParser';
// import updateSubscriptionSubscriberDataParser from './functionTypeParser/updateSubscriptionSubscriberDataParser';
// import deactivateScheduledTaskParser from './functionTypeParser/deactivateScheduledTaskParser';

function recursiveFunctionStepParser (
    functionStepsData=[], variableMap={},
    parameterObject={}, functionMap={}, variableIdMap={},
    setStateFunction, runTests=false,
    responseAccumulator={}, stepCount=0,
    prevStepId=''
){
    let accumulator = responseAccumulator;
    try{
        let variableObject = stepCount === 0 ? 
        variableMap
        :
        (
            accumulator[`${prevStepId}.${stepCount - 1}`] &&
            accumulator[`${prevStepId}.${stepCount - 1}`]['updatedVariableObject']
        ) || {};

        if(
            accumulator[`${prevStepId}.${stepCount - 1}`] &&
            accumulator[`${prevStepId}.${stepCount - 1}`]['updatedVariableObject']
        ){
            delete accumulator[`${prevStepId}.${stepCount - 1}`]['updatedVariableObject'];
        }
        
        if(Array.isArray(functionStepsData)){
            for (let i = 0; i < functionStepsData.length; i++){
                let functionObject = functionStepsData[i];
                let {
                    _id=''
                } = functionObject;

                accumulator = recursiveFunctionStepParser(
                    functionObject, variableObject, 
                    parameterObject, functionMap, variableIdMap, setStateFunction,
                    runTests, accumulator, i, functionStepsData[i - 1] && 
                    functionStepsData[i - 1]['_id']
                )
                
                if(
                    (accumulator[`${_id}.${i}`] && 
                    accumulator[`${_id}.${i}`]['hasReturnStatement'])
                    ||
                    (accumulator[`${_id}.${i}`] && 
                    accumulator[`${_id}.${i}`]['error'])
                ){
                    break;
                }
            }
        }else if(
            typeof functionStepsData === 'object' && 
            !Array.isArray(functionStepsData)
        ){
            const {
                functionType='', hasSteps=false,
                executionObject={}, functionSteps=[], _id=''
            } = functionStepsData;
            const {
                variableValue=[], mappingFunctions=[], inputText='',
                returnValue=[], ifElseArray=[], data={}, isVariable=false,
                varId='', selfId='', variableType=''
            } = executionObject;
            let identifier = `${_id}.${stepCount}`;
            if(functionType === 'setVariable'){
                let field = resolveExpression(
                    {
                        ...executionObject,
                        expressionMethods : [],
                        mappingFunctions : []
                    },
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap
                )
                let scriptValue = simpleScriptParser(
                    variableValue,
                    field.variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap
                );
                
                if(mappingFunctions.length){
                    variableObject = setMappedVariable(
                        field.value,
                        scriptValue.value,
                        mappingFunctions,
                        scriptValue.variableObject,
                        parameterObject,
                        functionMap,
                        variableIdMap
                    );
                    variableObject[selfId] = scriptValue.value;
                }else{
                    if(isVariable){
                        let varText = variableIdMap[variableType][varId];
                        variableObject[selfId] = scriptValue.value;
                        variableObject[varText] = scriptValue.value;
                    }else{
                        variableObject[field.value] = scriptValue.value;
                    }
                }
                
                accumulator[identifier] = {
                    updatedVariableObject : variableObject,
                    hasReturnStatement : false,
                    hasError : false,
                    returnStatement : null
                }
            }else if(functionType === 'returnStatement'){
                let scriptValue = simpleScriptParser(
                    returnValue,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap
                );
                accumulator[identifier] = {
                    hasReturnStatement : true,
                    returnStatement : scriptValue.value,
                    updatedVariableObject : scriptValue.variableObject,
                    hasError : false
                }
            }else if(functionType === 'ifElse'){
                let parsedIfElse = ifElseParser(
                    ifElseArray,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                )

                accumulator[identifier] = parsedIfElse;
            }else if(functionType === 'forLoop'){
                let parsedForLoop = forLoopParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                )

                accumulator[identifier] = parsedForLoop;
            }else if(functionType === 'continue'){
                accumulator[identifier] = {
                    continueLoop : true,
                    updatedVariableObject : variableObject
                }
            }else if(functionType === 'break'){
                accumulator[identifier] = {
                    breakLoop : true,
                    updatedVariableObject : variableObject
                }
            }else if(functionType === 'whileLoop'){
                let parsedWhileLoop = whileLoopParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );

                accumulator[identifier] = parsedWhileLoop;
            }else if(functionType === 'switch'){
                let parsedSwitchResponse = switchParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedSwitchResponse;
            }else if(functionType === 'dbOps'){
                let parsedDbOpsResponse = dbOpsParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedDbOpsResponse;
            }else if(functionType === 'networkRequest'){
                let parsedNRResponse = networkRequestParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedNRResponse;
            }else if(functionType === 'remoteNetworkRequest'){
                let parsedRNRResponse = remoteNetworkRequestParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedRNRResponse;
            }else if(functionType === 'consoleLog'){
                let parsedConsoleLog = resolveExpression(
                    data,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                );
                consoleLogParser(parsedConsoleLog.value)

                accumulator[identifier] = {
                    hasReturnStatement : false,
                    returnStatement : undefined,
                    updatedVariableObject : variableObject,
                    hasError : false,
                    errorPayload : undefined
                }
            }else if(functionType === 'setStateFunction'){
                if(setStateFunction){
                    let parsedAppData = resolveExpression(
                        data,
                        variableObject,
                        parameterObject,
                        functionMap,
                        variableIdMap,
                    );
                    let newState = parsedAppData.value;
                    if(
                        typeof newState === 'object' && 
                        !Array.isArray(newState)
                    ){
                        setStateFunction(newState);
                    }
                }

                accumulator[identifier] = {
                    hasReturnStatement : false,
                    returnStatement : undefined,
                    updatedVariableObject : variableObject,
                    hasError : false,
                    errorPayload : undefined
                }
            }else if(functionType === 'elementSetStateFunction'){
                let parsedElementState = elementSetStateParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedElementState;
            }else if(functionType === 'updateParentAppData'){
                let parsedParentState = updateParentAppDataParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedParentState;
            }else if(functionType === 'setAppLanguage'){
                if(setStateFunction){
                    let parsedLanguage = resolveExpression(
                        data,
                        variableObject,
                        parameterObject,
                        functionMap,
                        variableIdMap,
                    );
                    if(typeof parsedLanguage.value === 'string'){
                        setStateFunction({lang : parsedLanguage.value}, true)
                    }
                }

                accumulator[identifier] = {
                    hasReturnStatement : false,
                    returnStatement : undefined,
                    updatedVariableObject : variableObject,
                    hasError : false,
                    errorPayload : undefined
                }
            }else if(functionType === 'secretKey'){
                let parsedSecretKey = secretKeyParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedSecretKey;
            }else if(functionType === 'createScheduledTask'){
                let parsedCreateTask = createScheduledTaskParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedCreateTask;
            }else if(functionType === 'setTimeout'){
                let parsedSetTimeout = setTimeoutParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedSetTimeout;
            }else if(functionType === 'base64Decode'){
                let decodedBase64 = base64Parse(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = decodedBase64;
            }else if(functionType === 'base64Encode'){
                let encodedBase64 = base64Parse(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = encodedBase64;
            }else if(functionType === 'encrypt'){
                let parsedEncryption = parseCrypto(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedEncryption;
            }else if(functionType === 'decrypt'){
                let parsedDecryption = parseCrypto(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedDecryption;
            }else if([
                'setLocalStorage', 'getLocalStorage', 
                'setSessionStorage', 'getSessionStorage',
                'removeSessionStorage', 'removeLocalStorage'
            ].includes(functionType)){
                let parsedBrowserStorage = browserStorageParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedBrowserStorage;
            }else if(functionType === 'objectAssign'){
                let parsedMerge = objectAssignParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedMerge;
            }else if(['guid', 'shortid'].includes(functionType)){
                let parsedId = idParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedId;
            }else if(
                [
                    'windowPathname', 'windowHref',
                    'windowOrigin', 'windowHostname'
                ].includes(functionType)
            ){
                let parsedWindow = windowLocationParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedWindow;
            }else if(functionType === 'fileToBase64'){
                let parsedFileToBase64 = fileToBase64Parser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedFileToBase64;
            }else if(functionType === 'xmlToJson'){
                let parsedXmlToJson = xmlToJsonParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedXmlToJson;
            }else if(functionType === 'jsonToXml'){
                let parsedJsonToXml = jsonToXmlParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedJsonToXml;
            }else if(functionType === 'stringToArrayBuffer'){
                let parsedStringToArrayBuffer = stringToArrayBufferParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedStringToArrayBuffer;
            }else if(functionType === 'arrayBufferToBase64'){
                let parsedArrayBufferToBase64 = arrayBufferToBase64Parser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedArrayBufferToBase64;
            }else if(['jsonToCsv', 'csvToJson'].includes(functionType)){
                let parsedCsvJsonConverter = jsonToCsvParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedCsvJsonConverter;
            }else if(['compressString', 'decompressString'].includes(functionType)){
                let parsedCompression = compressDecompressParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedCompression;
            }else if(functionType === 'objectSize'){
                let parsedObjectSize = objectSizeParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedObjectSize;
            }else if(functionType === 'resizeImage'){
                let parsedImageResizer = imageResizeParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedImageResizer;
            }else if(functionType === 'getDomProperty'){
                let parsedDomProperty = getDomProperyParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedDomProperty;
            }else if(functionType === 'getDomMethod'){
                let parsedDomMethod = getDomProperyParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedDomMethod;
            }else if(functionType === 'setDomProperty'){
                let parsedSetProp = setDomPropertyParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedSetProp;
            }else if(
                [
                    'eventPreventDefault',
                    'eventStopPropagation'
                ].includes(functionType)
            ){
                let parsedEventMethod = eventBasicMethodParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedEventMethod;
            }else if(functionType === 'executeExternalFunction'){
                let parsedExternalFunction = executeExternalFunctionParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedExternalFunction;
            }else if(functionType === 'navigation'){
                let parsedNavigation = navigationParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedNavigation;
            }else if(functionType === 'getEnvironment'){
                let parsedEnvironment = getEnvironmentParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedEnvironment;
            }else if(
                [
                    'mysql', 'mssql', 'postgressql', 'oraclesql'
                ].includes(functionType)
            ){
                let parsedSql = sqlDbParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedSql;
            }else if(functionType === 'mongodb'){
                let parsedMongo = mongoDbParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedMongo;
            }else if(functionType === 'redis'){
                let parsedRedis = redisParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedRedis;
            }else if(functionType === 's3'){
                let parsedS3 = awsS3Parser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedS3;
            }else if([
                'getSubscribers', 'getSubscriberCount',
                'enrichSubscriberData', 'generateBrowserPdf',
                'addToSubscriptionUserBlockList',
                'removeFromSubscriptionUserBlockList',
                'addToSubscriptionUserAllowList',
                'removeFromSubscriptionUserAllowList',
                'getSubscriptionServiceTenants',
                'getSubscriptionServiceTenantCount',
                'updateSubscriptionServiceUserData',
                'deactivateScheduledTask', 'triggerSubscriptionOnDemandPayment',
                'paymentRefund', 'singlePaymentDistribution',
                'batchPaymentDistribution',
                'setResponseHeader', 'setResponseStatus',
                'setResponseCookies', 'createInhouzSignDocument',
                'cancelInhouzSignDocument', 'sendInhouzSignDocument',
                'getInhouzSignDocument', 'getInhouzSignDocuments',
                'getInhouzSignDocumentFile', 'deleteInhouzSignDocument',
                'generateInhouzSignUrl', 'createAnInhouzProject',
                'getInhouzProject', 'activateInhouzProject', 'deactivateInhouzProject',
                'changeInhouzProjectOwner', 'changeInhouzProjectStatus',
                'deleteInhouzProject', 'getInhouzProjects', 'getSystemUserApiKey',
                'indexedDbSet', 'indexedDbGet',
                'indexedDbSetMany', 'indexedDbGetMany',
                'indexedDbDelete', 'indexedDbDeleteMany',
                'indexedDbClear', 'indexedDbGetKeys', 'generateServerPdf'
            ].includes(functionType)){
                accumulator[identifier] = {
                    hasReturnStatement : false,
                    returnStatement : undefined,
                    updatedVariableObject : variableObject,
                    hasError : false,
                    errorPayload : undefined
                }
            }else if(functionType === 'jwtSign'){
                let parsedJwtSign = jwtSignParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedJwtSign;
            }else if(functionType === 'jwtVerify'){
                let parsedJwtVerify = jwtVerifyParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedJwtVerify;
            }else if(functionType === 'jwkToPem'){
                let parsedJwkToPem = jwkToPemParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedJwkToPem;
            }else if(functionType === 'geolocation.getCurrentPosition'){
                let parsedCurrentPosition = getCurrentPositionParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedCurrentPosition;
            }else if(functionType === 'geolocation.watchPosition'){
                let parsedWatchPosition = watchPositionParser(
                    functionStepsData,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                accumulator[identifier] = parsedWatchPosition;
            }
        }

        return accumulator;
    }catch(e){
        console.log('/utils/syncFunctionParser/recursiveFunctionStepParser catch block error', e);
        let id = functionStepsData['_id'];
        let identifier = `${id}.${stepCount}`;
        accumulator[identifier] = {
            errorPayload : e.stack,
            hasError : false,
            hasReturnStatement : false,
            returnStatement : null,
            updatedVariableObject : variableMap
        }
        return accumulator;
    }
}

export default recursiveFunctionStepParser;