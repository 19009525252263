import encryptDecrypt from '../../cryptography/encryptDecrypt';
import config from '../../../config/config';
import moment from 'moment';

export default async function (
    functionObj={},
    variableObject={},
    parameterObject={}
){
    try{
        const {
            executionObject={}
        } = functionObj;
        const {
            systemUserId='',
            variableName='', variableUnavailable=false
        } = executionObject;
        const {
            companyId='', inhouz_reserved_parameters_xyzh={}
        } = parameterObject;
        const {appEnvironment=''} = inhouz_reserved_parameters_xyzh;

        if(!variableName || variableUnavailable){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Variable name is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        if(
            !companyId ||
            typeof companyId !== 'string'
        ){
            variableObject[variableName] = {
                error : {
                    message : 'Owner ID is missing.'
                }
            }
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Owner ID is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        if(
            !systemUserId ||
            typeof systemUserId !== 'string'
        ){
            variableObject[variableName] = {
                error : {
                    message : 'System user ID is missing.'
                }
            }
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'System user ID is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let encryptedPayload = encryptDecrypt(
            JSON.stringify({
                payload : {
                    environment : appEnvironment,
                    systemUserId,
                    companyId
                },
                expirationTimestamp : moment().add(30, 'seconds').unix() * 1000
            }),
            true
        );

        //get secret
        let responseObj = await fetch(
            `${config.devServerProxy}/api/systemuser/getencryptedapikey`,
            {
                method : 'POST',
                headers : {
                    'Accept':"application/json",
                    'Content-Type':"application/json",
                    'Cache': 'no-cache'
                },
                credentials : 'omit',
                body : JSON.stringify({
                    encryptedPayload,
                    serverEncrypted : false
                })
            }
        )
        .then(resp => resp.json())
        .catch((error) => {
            console.log('getSystemUserApiKeyParser fetch catch block error', error);
            return {
                error : {
                    message : ''
                }
            };
        });

        if(responseObj.error){
            variableObject[variableName] = {
                error : {
                    message : responseObj.error.message || 'An error occured while getting secret securely.'
                }
            }
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : responseObj.error.message || 'An error occured while getting secret securely.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let {
            encryptedResponse=''
        } = responseObj;

        let apiKeyResponseObj = encryptDecrypt(encryptedResponse, false);
        let parsedApiResponse = JSON.parse(apiKeyResponseObj);
        variableObject[variableName] = typeof parsedApiResponse === 'object' && parsedApiResponse['API_KEY'] ?
        parsedApiResponse['API_KEY'] : parsedApiResponse;
        return {
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableObject,
            hasError : responseObj.hasError || responseObj.error ? true : false,
            errorPayload : responseObj.hasError || responseObj.error ? responseObj : undefined
        }
    }catch(e){
        console.log('/utils/functionParser/general/functionTypeParser/getSystemUserApiKeyParser catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        }
    }
}