import {decompress} from 'shrink-string';

export const setAppViewerReducer = (data={}) => dispatch => {
    dispatch({
        type : 'SET_APP_VIEWER_REDUCER',
        payload : data
    });
    // data = null;
    // dispatch = null;
}

export const setAppViewerAppObject = (data={}) => dispatch => {
    dispatch({
        type : 'SET_APP_VIEWER_APP_OBJECT',
        payload : data
    });
    // data = null;
    // dispatch = null;
}

export const setViewerElementAppData = (params={}) => dispatch => {
    dispatch({
        type : 'SET_VIEWER_ELEMENT_APP_DATA',
        payload : {
            data : params['data'],
            elementId : params['elementId'],
            refresh : params['refresh'] ? true : false,
            clearCache : params['clearCache'],
            appId : params['appId']
        }
    });
    // params = null;
    // dispatch = null;
}

export const initializeAppViewerApp = (data={}) => dispatch => {
    dispatch({
        type : 'INITIALIZE_APP_VIEWER_APP',
        payload : data
    });
    // data = null;
    // dispatch = null;
}

export const setAppviewerDataInReducer = (payload={}) => dispatch => {
    dispatch({
        type : 'SET_APP_VIEWER_DATA_IN_REDUCER',
        payload
    });
    // payload = null;
    // dispatch = null;
}

export const setViewerAppData = (data={}, appId='', updateSettings=false) => dispatch => {
    if(updateSettings){
        dispatch({
            type : 'SET_VIEWER_APP_SETTINGS',
            payload : {
                data,
                appId
            }
        });
    }else{
        dispatch({
            type : 'SET_VIEWER_APP_DATA',
            payload : {
                data,
                appId
            }
        });
    }

    // data = null;
    // appId = null;
    // updateSettings = null;
    // dispatch = null;
}

export const getWebsiteResources = (request={}) => async dispatch => {
    try{
        if(
            !request.hasError &&
            !request.error
        ){
            let {compressedResources=''} = request;
            let decompressed = await decompress(compressedResources);
            let parsedBuild = JSON.parse(decompressed);
            let {
                appId='', appElementsMap={}, appFunctionMap={}
            } = parsedBuild;
            dispatch({
                type : 'SET_WEBSITE_RESOURCES',
                payload : {
                    appId,
                    resources : {
                        appElementsMap,
                        appFunctionMap
                    }
                }
            });

            compressedResources = null;
            decompressed = null;
            parsedBuild = null;
            appId = null;
            appElementsMap = null;
            appFunctionMap = null;
        }

        // dispatch = null;
        request = null;
    }catch(e){
        console.log('/appViewerActions getWebsiteResources catch block error', e);
    }
}

export const setViewerWebComponent = (params={}) => async dispatch => {
    let {
        app={}, appId='', appInFocusId=''
    } = params;
    dispatch({
        type : 'SET_VIEWER_WEB_COMPONENT',
        payload : {
            appId,
            app,
            appInFocusId
        }
    });
    params = null;
    app = null;
    appId = null;
    appInFocusId = null;
    // dispatch = null;
}

export const updateGenericElementCacheInReducer = (params={}) => dispatch => {
    dispatch({
        type : 'UPDATE_GENERIC_ELEMENT_CACHE_IN_REDUCER',
        payload : {
            data : params['data'],
            elementId : params['elementId'],
            field : params['field']
        }
    });
    // params = null;
    // dispatch = null;
}

export const setBatchComputeCache = (payload={}) => dispatch => {
    dispatch({
        type : 'SET_BATCH_COMPUTE_CACHE',
        payload
    });
    // payload = null;
    // dispatch = null;
}

export const unsetBatchComputeCache = (key='') => dispatch => {
    dispatch({
        type : 'UNSET_BATCH_COMPUTE_CACHE',
        payload : {
            key
        }
    });
    // key = null;
    // dispatch = null;
}

export const setQuickUpdateDataCache = (payload={}) => dispatch => {
    dispatch({
        type : 'SET_QUICK_UPDATE_DATA_CACHE',
        payload
    });
    // payload = null;
    // dispatch = null;
}

export const setUiComponentCache = (elementType='') => dispatch => {
    dispatch({
        type : 'SET_UI_COMPONENT_CACHE',
        payload : {
            elementType
        }
    });
    // elementType = null;
    // dispatch = null;
}