import elementComponentNameMap from "../../components/customComponents/appViewer/utils/uiRenderUtils/elementComponentNameMap"
import elementLazyLoadMap from '../../components/customComponents/appViewer/utils/uiRenderUtils/elementLazyLoadMap';

const initialState = {
    cachedUiComponents : {}
}

export default function(state=initialState, action){
    switch(action.type){
        case 'SET_UI_COMPONENT_CACHE':
            if(
                action.payload.elementType &&
                !state.cachedUiComponents[elementComponentNameMap[action.payload.elementType]]
            ){
                let UiObj = {};
                let refresh = elementLazyLoadMap[elementComponentNameMap[action.payload.elementType]];
                if(!refresh || typeof refresh !== 'function'){
                    console.log('missing element', action.payload.elementType)
                }
                let elementComponent = refresh();
                // console.log('elementComponent', elementComponent)
                if(elementComponent._payload._status === 2){
                    console.log('import component failed', action.payload.elementType, elementComponent);
                    // elementComponent = refresh();
                    // elementComponent = refresh();
                    window.location.reload();
                }

                UiObj[elementComponentNameMap[action.payload.elementType]] = elementComponent;
                return {
                    ...state,
                    cachedUiComponents : {
                        ...state.cachedUiComponents,
                        ...UiObj
                    }
                }
            }else{
                return state;
            }
        default:
            return state;
    }
}